import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { IntakeSubmissionViewModel } from "@app/data/models";

@Component({
    selector: 'ui-session-intake-submission',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './session-intake-submission.component.html',
    styleUrls: ['./session-intake-submission.component.scss'],
})
export class SessionIntakeSubmissionComponent {
    @Input() intakeForms: IntakeSubmissionViewModel[];
}