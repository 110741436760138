import { Pipe, PipeTransform } from '@angular/core';

import { isString, isValidDate } from '../util';

@Pipe({
    name: 'isFutureDate',
})
export class IsFutureDatePipe implements PipeTransform {
    transform(value: Date | string | null): boolean {
        if (isString(value)) {
            value = new Date(value);
        }

        if (value && isValidDate(value)) {
            return value.getTime() > Date.now();
        }

        return false;
    }
}
