import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PaymentModel } from '@app/data/models';

@Component({
    selector: 'ui-view-payment',
    templateUrl: './view-payment.component.html',
    styleUrls: ['./view-payment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewPaymentComponent {
    @Input() payment: PaymentModel | null = null;
}
