import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <h2 matDialogTitle>Error occurred</h2>
        <mat-dialog-content>
            <ui-error-display fxFill *ngIf="data?.error" class="error-display" [error]="data?.error"></ui-error-display>
        </mat-dialog-content>
    `,
    styles: [
        `
            mat-icon {
                vertical-align: sub;
            }
        `,
    ],
})
export class ErrorDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
