<mat-card>
    <mat-card-header *ngIf="showTitle">
        <mat-card-title>
            {{ title }}
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ui-rich-text-view [content]="model?.description"></ui-rich-text-view>
        <ng-container *ngIf="hasBooked || scheduledEvent; else calendly">
            <p>You're meeting has been scheduled</p>
        </ng-container>
    </mat-card-content>
</mat-card>

<ng-template #calendly>
    <ng-container *ngFor="let id of ids; trackBy: trackBy">
        <div [attr.id]="id" class="calendly"></div>
    </ng-container>
</ng-template>
