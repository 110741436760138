import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Inject,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';

import { FormPage, LinkSessionSummary, Submission } from '@app/data/models';
import { APP_DOCUMENT } from '@app/shared/tokens';

import { ViewFormSubmissionsComponent } from '..';

@Component({
    selector: 'ui-print-form-submissions',
    templateUrl: './print-form-submissions.component.html',
    styleUrls: ['./print-form-submissions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintFormSubmissionsComponent implements OnChanges {
    iframe: HTMLIFrameElement | null = null;

    @ViewChild('printContainer', { read: ViewContainerRef }) container: ViewContainerRef;

    @Input() summaries: LinkSessionSummary[] | null = [];
    @Input() submissions: Submission[] | null = [];
    @Input() pages: FormPage[] | null = [];

    constructor(@Inject(APP_DOCUMENT) private document: Document) {}

    async onPrintSubmission(): Promise<void> {
        if (!this.container) return;

        this.container.clear();

        const componentRef = this.container.createComponent(ViewFormSubmissionsComponent);
        const instance = componentRef.instance;

        instance.summaries = this.summaries;
        instance.submissions = this.submissions;
        instance.pages = this.pages;

        instance.cd.detectChanges();
        instance.cd.markForCheck();

        this.iframe = this.document.createElement('iframe');
        this.document.body.appendChild(this.iframe);

        if (this.iframe.contentDocument) {
            this.iframe.contentDocument.body.innerHTML = componentRef.location.nativeElement.innerHTML;
            const styleSheets = Array.from(this.document.styleSheets);
            const allCss = [];

            for (let ix = 0; ix < styleSheets.length; ix++) {
                const styleSheet = styleSheets[ix];

                try {
                    allCss.push(
                        Array.from(styleSheet.cssRules)
                            .map(rule => rule.cssText)
                            .join(''),
                    );
                } catch (e) {
                    console.log('Access to stylesheet %s is denied. Ignoring...', styleSheet.href);

                    if (e instanceof DOMException && e.name === 'SecurityError' && styleSheet.href) {
                        try {
                            const response = await fetch(styleSheet.href);
                            const text = await response.text();
                            allCss.push(text);
                        } catch (e) {}
                    }
                }
            }

            this.iframe.contentDocument.head.innerHTML = `<style>${allCss.join('\n')}</style>`;

            setTimeout(() => {
                if (this.iframe) {
                    this.iframe.contentWindow?.focus();
                    this.iframe.contentWindow?.print();

                    setTimeout(() => {
                        if (this.iframe) {
                            this.container.clear();
                            if (this.iframe?.contentDocument) {
                                this.iframe.contentDocument.head.innerHTML = '';
                                this.iframe.contentDocument.body.innerHTML = '';
                            }

                            this.document.body.removeChild(this.iframe);
                        }
                    }, 1000);
                }
            }, 1000);
        }
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes['summaries'] && changes['summaries'].currentValue) {
            await this.onPrintSubmission();
        }
    }
}
