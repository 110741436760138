<mat-toolbar>
    <mat-toolbar-row>
        <button mat-icon-button (click)="onToggleNavigation()">
            <mat-icon
                matBadgePosition="after"
                matBadgeOverlap="false"
                class="badge"
                [ngClass]="[badgeColor]"
                [matBadgeHidden]="!showBadge"
                [matBadge]="badgeText"
                >menu</mat-icon
            >
        </button>
        <form *ngIf="showSearch" [formGroup]="form">
            <input placeholder="Search" autocomplete="off" matInput formControlName="search" [searchResults]="auto" />
        </form>
        <ng-content></ng-content>
    </mat-toolbar-row>
</mat-toolbar>
<ui-search-results #auto (optionSelected)="onSelectNode($event)">
    <mat-list class="search-results" *ngIf="isSearchEnabled && (searchResults | any)">
        <ng-container [ngSwitch]="result.type" *ngFor="let result of searchResults; let isLast = last">
            <mat-list-item *ngSwitchCase="SearchResultType.Node" class="item" [searchResultItem]="result">
                <mat-icon matListItemIcon>{{ result.data | nodeTypeIcon }}</mat-icon>
                <h3 class="title" matListItemLine [innerHTML]="result.data.title"></h3>
                <i class="path" matListItemLine *ngIf="result.path">{{ result.path }}</i>
                <p
                    class="body"
                    mat-subheader
                    matListItemLine
                    *ngFor="let token of result.tokens"
                    [innerHTML]="token | highlight: search.value"
                ></p>
                <div matListItemLine>
                    <ul class="keywords" *ngIf="result.keywords.size > 0">
                        <li>Keywords:</li>
                        <li *ngFor="let keyword of result.keywords | keyvalue">{{ keyword.key }} x{{ keyword.value }}</li>
                    </ul>
                </div>
            </mat-list-item>
            <mat-list-item *ngSwitchCase="SearchResultType.Note" class="item" [searchResultItem]="result">
                <mat-icon matListItemIcon>note</mat-icon>
                <h3 class="title" matListItemLine [innerHTML]="result.data.title"></h3>
                <p
                    class="body"
                    mat-subheader
                    matListItemLine
                    *ngFor="let token of result.tokens"
                    [innerHTML]="token | highlight: search.value"
                ></p>
                <div matListItemLine>
                    <ul class="keywords" *ngIf="result.keywords.size > 0">
                        <li>Keywords:</li>
                        <li *ngFor="let keyword of result.keywords | keyvalue">{{ keyword.key }} x{{ keyword.value }}</li>
                    </ul>
                </div>
            </mat-list-item>
        </ng-container>
    </mat-list>
</ui-search-results>
