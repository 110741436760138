import { Inject, Pipe, PipeTransform } from '@angular/core';
import { LinkType } from '../enums';
import { formatLinkUrl } from '../util';
import { APP_ENVIRONMENT } from '../tokens';
import { Environment } from '../models';

@Pipe({
    name: 'formatLinkUrl',
})
export class FormatLinkUrlPipe implements PipeTransform {
    constructor(@Inject(APP_ENVIRONMENT) private env: Environment) {}
    transform(value: { rowKey: string | null; type: LinkType; url: string } | null): string {
        if (!value) {
            return '';
        }
        return formatLinkUrl(value, this.env);
    }
}
