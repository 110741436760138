import { Directive, EventEmitter, HostListener, Output, ElementRef } from '@angular/core';

@Directive({
    selector: '[outsideClick]',
})
export class OutsideClickDirective {
    @Output() readonly outsideClick: EventEmitter<MouseEvent> = new EventEmitter();

    @HostListener('document:mousedown', ['$event'])
    onClick(event: MouseEvent): void {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            const overlay = document.querySelector('.cdk-overlay-container');

            if (overlay && overlay.contains(event.target as any)) {
                return;
            }

            this.outsideClick.emit(event);
        }
    }
    constructor(private elementRef: ElementRef) {}
}
