import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { ClipboardModel, Registrant, ZoomRegistrationModel } from '@app/data/models';
import { RegistrationType } from '@app/shared/enums';

export interface MeetingSectionModel {
    id: string;

    title: string;
    description: string;

    allowGuestRegistration: boolean;

    showJoinUrl: boolean;
    showJoinButton: boolean;
    showCopyLinkButton: boolean;
}
@Component({
    selector: 'ui-form-section-meeting',
    templateUrl: './form-section-meeting.component.html',
    styleUrls: ['./form-section-meeting.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSectionMeetingComponent implements OnInit, OnChanges {
    isRegistering = false;
    isJoiningMeeting = false;
    isRequestingLink = false;

    form = this.fb.group({
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
    });

    get showTitle() {
        return this.model && this.model.title;
    }

    get title() {
        return this.model?.title;
    }

    get hasMeetingStarted(): boolean {
        return this.registrant?.joinUrl !== '' && this.registrant?.hasStarted === true;
    }

    get showJoinUrl(): boolean {
        return this.model?.showJoinUrl === true;
    }

    get showJoinButton(): boolean {
        return this.model?.showJoinButton === true;
    }

    get showCopyLinkButton(): boolean {
        return this.model?.showCopyLinkButton === true;
    }

    get allowGuestRegistration(): boolean {
        return this.model?.allowGuestRegistration === true;
    }

    get guestLink(): string {
        return this.registrant?.guestLink || '';
    }

    get joinUrl(): string {
        return this.registrant?.joinUrl || '';
    }

    @Input() error: any;
    @Input() model: MeetingSectionModel;
    @Input() registrant: Registrant | null = null;

    @Output() readonly registerForMeeting = new EventEmitter<ZoomRegistrationModel>();
    @Output() readonly requestGuestLink = new EventEmitter<void>();
    @Output() readonly joinMeeting = new EventEmitter<void>();
    @Output() readonly copyToClipboard = new EventEmitter<ClipboardModel>();
    @Output() readonly loadRegistrant = new EventEmitter<void>();

    constructor(private fb: FormBuilder) {}

    ngOnInit() {
        this.loadRegistrant.emit();
    }

    onCopyLinkToClipboard() {
        this.copyToClipboard.emit({
            text: this.joinUrl,
            message: 'Link copied to clipboard',
        });
    }

    onRegister() {
        this.isRegistering = true;
        this.registerForMeeting.emit({
            firstName: this.form.value.firstName || '',
            lastName: this.form.value.lastName || '',
            email: this.form.value.email || '',
            sectionId: this.model.id,
            registrationType: RegistrationType.Meeting,
        });
    }

    onJoinMeeting() {
        this.isJoiningMeeting = true;
        this.joinMeeting.emit();
    }

    onRequestGuestLink() {
        this.isRequestingLink = true;
        this.requestGuestLink.emit();
    }

    onCopyLink() {
        this.copyToClipboard.emit({
            text: this.registrant?.guestLink || '',
            message: 'Link copied to clipboard',
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['registrant']) {
            this.form.patchValue({
                firstName: this.registrant?.firstName,
                lastName: this.registrant?.lastName,
                email: this.registrant?.email,
            });

            if (!this.registrant?.joinUrl) {
                this.isRegistering = false;
            }
        }

        if (changes['error'] && this.error) {
            this.isRegistering = false; // allow user to try again
        }
    }
}
