import { Pipe, PipeTransform } from '@angular/core';
import { Timezone } from 'timezones.json';
import { DayOfWeek, LinkExpiration, LinkType, RegistrationMode, TimerUnit } from '../enums';
import { DEFAULT_TIME_ZONE, formatDatetimeWithTimeZone, getTimeZoneByIanaTimeZone } from '../util';

@Pipe({
    name: 'formatLinkExpiry',
})
export class FormatLinkExpiryPipe implements PipeTransform {
    transform(model: {
        type: LinkType;
        linkExpiration: LinkExpiration;
        timeZone: string;
        registrationMode: RegistrationMode;
        requiresApproval: boolean;
        timerUnit: TimerUnit;
        timer?: number | null;
        endDate?: Date | null;
        dayOfWeek?: DayOfWeek;
    }): string {
        const timezone = getTimeZoneByIanaTimeZone(model.timeZone || DEFAULT_TIME_ZONE) as Timezone;
        const registrationModes = [RegistrationMode.Daily, RegistrationMode.Weekly, RegistrationMode.Monthly];
        const hasSubmissionReset = registrationModes.includes(model.registrationMode);
        const dayOfWeek = DayOfWeek[model.dayOfWeek as DayOfWeek];

        switch (model.linkExpiration) {
            case LinkExpiration.Timer:
                {
                    const w1 = model.requiresApproval ? 'approval' : 'activation';
                    switch (model.timerUnit) {
                        case TimerUnit.Months:
                            return `Timer length after ${w1} (${model.timer}m)`;
                        case TimerUnit.Weeks:
                            return `Timer length after ${w1} (${model.timer}w)`;
                        case TimerUnit.Days:
                            return `Timer length after ${w1} (${model.timer}d)`;
                        case TimerUnit.Hours:
                            return `Timer length after ${w1} (${model.timer}h)`;
                        case TimerUnit.Minutes:
                            return `Timer length after ${w1} (${model.timer}min)`;
                        default:
                    }
                }
                break;
            case LinkExpiration.Date:
                if (model.endDate && !hasSubmissionReset) {
                    return `Expires ${formatDatetimeWithTimeZone(model.endDate, timezone)}`;
                } else if (hasSubmissionReset && model.type === LinkType.Shareable) {
                    const reset =
                        model.registrationMode === RegistrationMode.Daily
                            ? `Daily Reset @ 11:59pm`
                            : model.registrationMode === RegistrationMode.Weekly
                            ? `Weekly Reset ${dayOfWeek} @ 11:59pm`
                            : 'Monthly Reset @ 11:59pm';

                    return `${reset} ${timezone.abbr}`;
                }
                break;
            default:
                if (hasSubmissionReset) {
                    const reset =
                        model.registrationMode === RegistrationMode.Daily
                            ? `Daily Reset @ 11:59pm`
                            : model.registrationMode === RegistrationMode.Weekly
                            ? `Weekly Reset ${dayOfWeek} @ 11:59pm`
                            : 'Monthly Reset @ 11:59pm';

                    return `${reset} ${timezone.abbr}`;
                }
        }

        return '';
    }
}
