import { MiniPlayerState } from '@app/shared/enums';

const ns = '[Media Player]';

export class LoadAudio {
    static readonly type = `${ns} Load Audio`;
    constructor(
        public id: string,
        public title: string | null,
        public src: string | File,
        public canSeek = true,
        public startTime = 0,
        public reportProgress = false,
    ) {}
}

export class PlayMedia {
    static readonly type = `${ns} Play Media`;
    constructor(public startTime = 0) {}
}

export class PauseMedia {
    static readonly type = `${ns} Pause Media`;
}

export class SeekMedia {
    static readonly type = `${ns} Seek Media`;
    constructor(public amount: number) {}
}

export class PlaybackRate {
    static readonly type = `${ns} Playback Rate`;
    constructor(public rate: number) {}
}

export class ReportProgress {
    static readonly type = `${ns} Report Progress`;
    constructor(public id: string | null, public position: number) {}
}

export class PlaybackEnded{
    static readonly type = `${ns} Playback Ended`;
}

export class SetMiniPlayerVisibility {
    static readonly type = `${ns} Set Mini Player Visibility`;
    constructor(public state: MiniPlayerState, public options?: { reportProgress: boolean; allowScrubbing: boolean }) {}
}
