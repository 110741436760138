<mat-card>
    <mat-card-content>
        <ui-rich-text-view *ngIf="showTitle" [content]="title"></ui-rich-text-view>
        <div>
            <div [ngClass]="{ readonly: readonly }">
                <ng-container [ngSwitch]="type">
                    <ng-container *ngSwitchCase="FormQuestionType.ShortAnswer" [ngTemplateOutlet]="short"></ng-container>
                    <ng-container *ngSwitchCase="FormQuestionType.LongAnswer" [ngTemplateOutlet]="long"></ng-container>
                    <ng-container
                        *ngSwitchCase="FormQuestionType.MultipleChoice"
                        [ngTemplateOutlet]="multiple"
                    ></ng-container>
                    <ng-container *ngSwitchCase="FormQuestionType.Checkbox" [ngTemplateOutlet]="checkboxes"></ng-container>
                    <ng-container *ngSwitchCase="FormQuestionType.Dropdown" [ngTemplateOutlet]="dropdown"></ng-container>
                    <ng-container *ngSwitchCase="FormQuestionType.Scale" [ngTemplateOutlet]="scale"></ng-container>
                    <ng-container *ngSwitchCase="FormQuestionType.Date" [ngTemplateOutlet]="date"></ng-container>
                    <ng-container *ngSwitchCase="FormQuestionType.Matrix" [ngTemplateOutlet]="matrix"></ng-container>
                    <ng-container
                        *ngSwitchCase="FormQuestionType.MatrixCheckbox"
                        [ngTemplateOutlet]="matrixCheckbox"
                    ></ng-container>
                </ng-container>
            </div>
            <div>
                <ng-content></ng-content>
            </div>
        </div>
    </mat-card-content>
</mat-card>

<ng-template #short>
    <div [formGroup]="form">
        <mat-form-field appearance="outline" class="full-width">
            <input formControlName="value" [ngClass]="{ hidden: print }" matInput />
            <div *ngIf="print">{{ form.get('value')?.value }}</div>
            <mat-error *ngIf="form.get('value')?.hasError('required')">Required</mat-error>
        </mat-form-field>
    </div>
</ng-template>

<ng-template #long>
    <div [formGroup]="form">
        <mat-form-field appearance="outline" class="full-width">
            <textarea formControlName="value" [ngClass]="{ hidden: print }" matInput></textarea>
            <div *ngIf="print">{{ form.get('value')?.value }}</div>
            <mat-error *ngIf="form.get('value')?.hasError('required')">Required</mat-error>
        </mat-form-field>
    </div>
</ng-template>

<ng-template #multiple>
    <div [formGroup]="form">
        <mat-radio-group formControlName="value">
            <div class="option" *ngFor="let option of options">
                <mat-radio-button [value]="option['id']">
                    {{ option['text'] }}
                </mat-radio-button>
            </div>
            <div class="option" *ngIf="showOtherOption">
                <mat-radio-button>
                    <mat-form-field appearance="outline">
                        <mat-label>Other</mat-label>
                        <input matInput formControlName="other" [ngClass]="{ hidden: print }" />
                        <div *ngIf="print">{{ form.get('other')?.value }}</div>
                        <mat-error *ngIf="form.get('other')?.hasError('required')">Required</mat-error>
                        <mat-error *ngIf="form.get('other')?.hasError('minlength')">Min length is 15 characters</mat-error>
                    </mat-form-field>
                </mat-radio-button>
            </div>
            <mat-error class="radio-required" *ngIf="form.get('value')?.hasError('required')">Required</mat-error>
        </mat-radio-group>
    </div>
</ng-template>

<ng-template #matrix>
    <div [formGroup]="form">
        <div class="matrix-row">
            <div class="label">&nbsp;</div>
            <div class="matrix">
                <div class="matrix-value" *ngFor="let col of model?.matrix?.columns">
                    {{ col.text }}
                </div>
            </div>
        </div>
        <div formArrayName="value" *ngFor="let row of model?.matrix?.rows; let ix = index">
            <div class="matrix-row">
                <div class="label">{{ row.text }}</div>
                <mat-radio-group class="matrix" [formControlName]="ix">
                    <mat-radio-button class="matrix-value" *ngFor="let col of model?.matrix?.columns" [value]="col.id">
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <mat-error *ngIf="form.get('value')?.hasError('required')">Required</mat-error>
    </div>
</ng-template>

<ng-template #matrixCheckbox>
    <div [formGroup]="form">
        <div class="matrix-row">
            <div class="label">&nbsp;</div>
            <div class="matrix">
                <div class="matrix-value" *ngFor="let col of model?.matrix?.columns">
                    {{ col.text }}
                </div>
            </div>
        </div>
        <div formArrayName="value" *ngFor="let row of model?.matrix?.rows; index as rowIx">
            <div class="matrix-row" [formGroupName]="rowIx">
                <div class="label">{{ row.text }}</div>
                <div class="matrix">
                    <ng-container *ngFor="let col of model?.matrix?.columns; index as colIx">
                        <div [formGroupName]="colIx">
                            <mat-checkbox
                                class="matrix-value"
                                formControlName="checked"
                                [value]="getMatrixCtrl(rowIx, colIx).value.id"
                                [checked]="getMatrixCtrl(rowIx, colIx).value.checked"
                                (change)="onCheckChanged()"
                            ></mat-checkbox>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <mat-error *ngIf="form.get('value')?.hasError('required')">Required</mat-error>
    </div>
</ng-template>

<ng-template #checkboxes>
    <div class="q-checkboxes" [formGroup]="form">
        <div formArrayName="options" class="option" *ngFor="let ctrl of optionsCtrl?.controls; let ix = index">
            <div [formGroupName]="ix">
                <mat-checkbox
                    formControlName="checked"
                    [value]="ctrl.value.id"
                    [checked]="ctrl.value.checked"
                    (change)="onCheckChanged(ctrl, ix)"
                    >{{ ctrl.value.text }}</mat-checkbox
                >
            </div>
        </div>
        <div class="option other" *ngIf="showOtherOption">
            <mat-checkbox
                #otherCheckbox
                (change)="onOtherValueChange($event)"
                [checked]="isOtherValueChecked"
            ></mat-checkbox>
            <mat-form-field appearance="outline">
                <mat-label>Other</mat-label>
                <input matInput formControlName="other" [ngClass]="{ hidden: print }" />
                <div *ngIf="print">{{ form.get('other')?.value }}</div>
                <mat-error *ngIf="form.get('other')?.hasError('required')">Required</mat-error>
                <mat-error *ngIf="form.get('other')?.hasError('minlength')">Min length is 15 characters</mat-error>
            </mat-form-field>
        </div>
    </div>
</ng-template>

<ng-template #dropdown>
    <div [formGroup]="form">
        <mat-form-field appearance="outline">
            <mat-select formControlName="value" [ngClass]="{ hidden: print }">
                <mat-option *ngFor="let option of options; let ix = index" [value]="option['id']">
                    {{ option['text'] }}
                </mat-option>
            </mat-select>
            <div *ngIf="print">{{ getPrintValue() }}</div>
            <mat-error *ngIf="form.get('value')?.hasError('required')">Required</mat-error>
        </mat-form-field>
    </div>
</ng-template>

<ng-template #scale>
    <div class="scale" [formGroup]="form">
        <div class="label" fxLayout="row nowrap">
            <div fxFlex="0 0 auto">{{ minLabel }}</div>
            <div fxFlex="1 1"></div>
            <div fxFlex="0 0 auto">{{ maxLabel }}</div>
        </div>
        <!-- <mat-slider formControlName="value" [tickInterval]="1" [min]="min" [max]="max" step="1"></mat-slider> -->
        <mat-radio-group formControlName="value">
            <div fxLayout="row nowrap" fxLayoutAlign="space-between">
                <mat-radio-button fxFlex="0 0 auto" *ngFor="let option of options" [value]="option['id']">
                    {{ option['text'] }}
                </mat-radio-button>
            </div>
        </mat-radio-group>
    </div>
</ng-template>

<ng-template #date>
    <div [formGroup]="form">
        <mat-form-field appearance="outline">
            <input
                formControlName="value"
                [matDatepicker]="datePicker"
                [min]="minDateValue"
                matInput
                [ngClass]="{ hidden: print }"
            />
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
            <div *ngIf="print">{{ getPrintValue() }}</div>
            <mat-error *ngIf="form.get('value')?.hasError('required')">Required</mat-error>
        </mat-form-field>
    </div>
</ng-template>
