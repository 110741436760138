import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <h2 matDialogTitle><mat-icon>app_blocking</mat-icon> Device not supported</h2>
        <mat-dialog-content>
            At this time, iPhone/iPad is not supported. Please use an alternative device until further notice.
        </mat-dialog-content>
    `,
    styles: [
        `
            mat-icon {
                vertical-align: sub;
            }
        `,
    ],
})
export class DenyiOSDialogComponent {}
