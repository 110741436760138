<section>
    <ng-container *ngFor="let page of pages; index as ix; first as isFirstPage">
        <div class="divider">
            <mat-divider *ngIf="!isFirstPage"> </mat-divider>
            <mat-icon [fxHide]="isFirstPage">unfold_more</mat-icon>
            Page {{ ix + 1 }} of {{ pages?.length }}
        </div>
        <ui-form-section [model]="page"></ui-form-section>

        <div fxLayout="column nowrap" fxLayoutAlign="center" class="sections" *ngIf="page.children | any">
            <div fxFlex="0 0 100%" *ngFor="let child of page.children; index as ix; first as isFirst">
                <div class="divider"></div>
                <ng-container [ngSwitch]="child.sectionType">
                    <ui-form-section-text
                        class="section"
                        *ngSwitchCase="FormSectionType.Text"
                        [model]="child"
                    ></ui-form-section-text>

                    <mat-card *ngSwitchCase="FormSectionType.Question">
                        <mat-card-header>
                            <mat-card-title [innerHTML]="child.title | safeHtml"></mat-card-title>
                            <mat-card-subtitle>{{ getTotalSubmissionsForSection(child) }} Submissions</mat-card-subtitle>
                        </mat-card-header>
                        <mat-card-content>
                            <ng-container [ngSwitch]="child.type">
                                <ng-container *ngSwitchCase="FormQuestionType.Matrix">
                                    <ng-container
                                        *ngTemplateOutlet="matrix; context: { section: child, rows: child?.matrix?.rows }"
                                    >
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngSwitchCase="FormQuestionType.MatrixCheckbox">
                                    <ng-container
                                        *ngTemplateOutlet="matrix; context: { section: child, rows: child?.matrix?.rows }"
                                    >
                                    </ng-container>
                                </ng-container>
                                <ui-form-section-chart
                                    *ngSwitchDefault
                                    [section]="child"
                                    [submissions]="submissions"
                                    [sessions]="sessions"
                                    [includeDraftResponses]="includeDraftResponses"
                                    (showDetails)="onShowDetails($event)"
                                ></ui-form-section-chart>
                            </ng-container>
                        </mat-card-content>
                    </mat-card>
                </ng-container>
            </div>
        </div>
    </ng-container>
</section>

<ng-template
    cdk-connected-overlay
    cdkConnectedOverlayLockPosition
    cdkConnectedOverlayHasBackdrop
    cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
    [cdkConnectedOverlayScrollStrategy]="_scrollStrategy"
    [cdkConnectedOverlayOrigin]="_overlayOrigin"
    [cdkConnectedOverlayOpen]="panelOpen"
    [cdkConnectedOverlayPositions]="_positions"
    [cdkConnectedOverlayWidth]="_overlayWidth"
    (backdropClick)="close()"
    (attach)="_onAttached()"
    (detach)="close()"
>
    <div #panel role="listbox" tabindex="-1" class="mat-mdc-select-panel mdc-menu-surface mdc-menu-surface--open">
        <ui-form-chart-tooltip (selectItem)="onSelectItem($event)" (copyToClipboard)="onCopyToClipboard($event)" [link]="link" [category]="tooltipLabel"  [sessions]="tooltipSessions"></ui-form-chart-tooltip>
    </div>
</ng-template>

<ng-template #matrix let-section="section" let-rows="rows">
    <ng-container *ngFor="let row of rows; index as ix">
        <h3>{{ rows[ix].text }}</h3>
        <ui-form-section-chart
            [matrixRowIndex]="ix"
            [section]="section"
            [submissions]="submissions"
            [includeDraftResponses]="includeDraftResponses"
        >
        </ui-form-section-chart>
    </ng-container>
</ng-template>
