import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CalendlyComponent } from './calendly.component';

const ROUTES: Routes = [
    {
        path: '',
        loadChildren: () => import('@app/admin').then(m => m.AdminModule),
        data: {
            title: 'Shareable Link',
        },
    },
    {
        path: 'calendly',
        component: CalendlyComponent,
    },
    {
        path: '**',
        redirectTo: '',
        pathMatch: 'full',
    },
];
@NgModule({
    imports: [RouterModule.forRoot(ROUTES)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
