import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PaymentModel } from '@app/data/models';
import { PaymentStatus } from '@app/shared/enums';

@Component({
    selector: 'ui-form-section-summary',
    templateUrl: './form-section-summary.component.html',
    styleUrls: ['./form-section-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSectionSummaryComponent {
    PaymentStatus: typeof PaymentStatus = PaymentStatus;

    @Input() payments: PaymentModel[] | null = null;
}
