<div *ngIf="isDiagnosisEnabled$ | async">
    <div>
        <span *ngIf="getDiagnosticResult$ | async as result; else send">
            <a target="_blank" rel="nofollow" [href]="supportLink$ | async"
                >Click here to enter the WhatsApp support thread.</a
            >
            Once you enter the thread, please share your name and a brief description of the challenge you encountered.
        </span>
    </div>
</div>

<ng-template #send>
    If you're unable to resolve the above issue, you can enter the WhatsApp support thread by clicking the button below.
    <button mat-raised-button color="primary" [disabled]="dataSent" (click)="onSendDiagnosisData()">
        Whatsapp Support Thread
    </button>
</ng-template>
