<mat-card>
    <mat-card-header>
        <mat-card-title>Payment Summary</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-list>
            <mat-list-item *ngFor="let payment of payments">
                <mat-icon class="complete" matListItemIcon>credit_score</mat-icon>
                <div matListItemTitle>{{ payment.productName }} ({{ payment.productCode }})</div>
                <div matListItemLine>{{ payment.amount | currency: payment.merchantCurrency }}</div>
                <div matListItemLine>{{ payment.status | paymentStatus }}</div>
                <div matListItemLine>Sold To: {{ payment.payerName }} ({{ payment.payerEmail }})</div>
            </mat-list-item>
        </mat-list>
    </mat-card-content>
</mat-card>
