import { Pipe, PipeTransform } from '@angular/core';

import { NodeType } from '../enums';

@Pipe({
    name: 'nodeTypeIcon',
})
export class NodeTypeIconPipe implements PipeTransform {
    transform(value: any): string {
        if (!value) {
            return '';
        }

        switch (value.type) {
            case NodeType.Audio:
                return 'volume_up';
            case NodeType.Html:
                return 'code';
            case NodeType.Text:
                return 'text_format';
            case NodeType.Video:
                return 'movie';
            case NodeType.File:
                return 'attach_file';
            case NodeType.Node:
                return 'folder';
            default:
                return '';
        }
    }
}
