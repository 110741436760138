import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';

import { AsyncSubject, filter, Subject, takeUntil, tap } from 'rxjs';

import { FormSection, LinkSessionSummary, Submission } from '@app/data/models';
import { FormQuestionType } from '@app/shared/enums';
import { BarElement, Chart, ChartConfiguration, ChartData, ChartDataset, ChartEvent } from 'chart.js';
import * as scale from 'd3-scale';

export interface GraphEvent {
    event: ChartEvent;
    elements: { index: number; datasetIndex: number; element: BarElement }[];
}

@Component({
    selector: 'ui-form-section-chart',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './form-section-chart.component.html',
    styleUrls: ['./form-section-chart.component.scss'],
})
export class FormSectionChartComponent implements AfterViewInit, OnInit, OnDestroy, OnChanges {
    isAlive$ = new AsyncSubject<void>();
    renderCheck$ = new Subject<void>();
    onClick$ = new Subject<any>();

    afterViewInit = false;
    datasetVisitors: string[][] = [];

    chart: Chart;

    get showChart() {
        const allowedTypes = [
            FormQuestionType.MultipleChoice,
            FormQuestionType.Checkbox,
            FormQuestionType.Dropdown,
            FormQuestionType.Scale,
            FormQuestionType.Matrix,
            FormQuestionType.MatrixCheckbox,
        ];

        return allowedTypes.includes(this.section?.type as FormQuestionType);
    }

    @ViewChild('canvas') canvasEl: ElementRef<HTMLCanvasElement>;

    @Input() includeDraftResponses = false;
    @Input() section: FormSection | null = null;
    @Input() matrixRowIndex = 0;
    @Input() submissions: Submission[] | null = [];
    @Input() sessions: LinkSessionSummary[] | null;

    @Output() readonly showDetails = new EventEmitter<{
        event: PointerEvent | MouseEvent;
        sessions: LinkSessionSummary[];
        section: FormSection;
        dataIndex: number;
    }>();

    ngOnInit() {
        this.renderCheck$
            .pipe(
                takeUntil(this.isAlive$),
                filter(() => (this.afterViewInit && this.section !== null && this.submissions !== null) === true),
                tap(() => {
                    this.updateChart();
                }),
            )
            .subscribe();

        this.onClick$
            .pipe(
                takeUntil(this.isAlive$),
                filter(e => (this.afterViewInit && this.section !== null && this.submissions !== null) === true),
                tap(({ event, elements }: GraphEvent) => {
                    const dataSessions = elements.length > 0 ? this.datasetVisitors[elements[0].index] : [];
                    // const dataLabel = elements.length > 0 ? this.section?.options[elements[0].index]['text'] : '';
                    const sessions = this.sessions?.filter(s => dataSessions.includes(s.visitorId)) || [];

                    if (sessions.length > 0) {
                        this.showDetails.emit({
                            event: event.native as PointerEvent,
                            sessions,
                            section: this.section as FormSection,
                            dataIndex: elements[0].index,
                        });
                    }
                }),
            )
            .subscribe();
    }

    generateLabel(s: string): string | string[] {
        if (s.length > 20) {
            return s
                .split(' ')
                .reduce((acc, word) => {
                    const len = acc.length;
                    if (len > 0 && acc[len - 1].length < 10) {
                        acc[len - 1].push(word);
                    } else {
                        acc.push([word]);
                    }

                    return acc;
                }, [] as string[][])
                .reduce((acc, words, ix) => {
                    acc[ix] = words.join(' ');

                    return acc;
                }, []);
        }

        return s as string;
    }

    generateBackgroundColours(count: number): any[] {
        const colors = scale
            .scaleLinear()
            .domain([0, count])
            .range(['#a0ccff', '#003066'] as any);
        const backgroundColor = [];
        for (let ix = 0; ix < count; ix++) {
            backgroundColor.push(colors(ix));
        }

        return backgroundColor;
    }

    buildChart(): void {
        if (!this.section) return;

        const labels = [];
        const dataSets = [];
        const submissions = this.submissions as Submission[];
        const section = this.section as FormSection;
        const max = this.section.options.length;
        const isCheckbox = section.type === FormQuestionType.Checkbox;

        labels.push(...this.section.options.map(o => this.generateLabel(o['text'].toString())));
        this.datasetVisitors = [];

        dataSets.push(
            ...section.options.map(o => {
                const visitorIds: string[] = [];

                const total = submissions.reduce((total, s) => {
                    const results = s[this.includeDraftResponses ? 'results' : 'submittedResults'].filter(
                        r =>
                            r.id === section.id &&
                            (isCheckbox ? (r.value as string[]).includes(o['id'] as string) : r.value === o['id']),
                    );

                    if (results.length > 0) {
                        visitorIds.push(s.visitorId);
                    }

                    return total + results.length;
                }, 0);

                this.datasetVisitors.push(visitorIds);
                return total;
            }),
        );

        if (this.section.showOther) {
            labels.push('Other');
            const options = this.section.options.map(o => o['id']);
            const visitorIds: string[] = [];

            dataSets.push(
                submissions.reduce((total, s) => {
                    const results = s[this.includeDraftResponses ? 'results' : 'submittedResults'].filter(
                        r =>
                            r.id === section.id &&
                            (isCheckbox
                                ? (r.value as string[]).filter(e => !options.includes(e)).length > 0
                                : !options.includes(r.value as string)),
                    );

                    if (results.length > 0) {
                        visitorIds.push(s.visitorId);
                    }

                    return total + results.length;
                }, 0),
            );

            this.datasetVisitors.push(visitorIds);
        }

        const backgroundColor = this.generateBackgroundColours(max);

        const ctx = this.canvasEl.nativeElement.getContext('2d');
        const data: ChartData<'bar', number[], unknown> = {
            labels,

            datasets: [
                {
                    data: dataSets,
                    backgroundColor,

                    // hoverOffset: 4,
                },
            ],
        };

        const config: ChartConfiguration<'bar', number[]> = {
            type: 'bar',
            data: data,

            options: {
                indexAxis: 'y',
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        beginAtZero: true,
                        ticks: {
                            precision: 0,
                        },
                    },
                },

                plugins: {
                    legend: {
                        display: false,
                    },
                },

                onClick: (event, elements, chart) => {
                    this.onClick$.next({ elements, chart, event });
                },
            },
        };

        if (this.chart) {
            this.chart.destroy();
        }

        this.chart = new Chart(ctx as any, config);
    }

    buildChartForMatrix(): void {
        const labels = [];
        const datasets: ChartDataset<'bar', number[]>[] = [];
        const submissions = this.submissions as Submission[];
        const section = this.section as FormSection;
        const max = section.matrix.columns.length;
        const backgroundColor = this.generateBackgroundColours(max);
        labels.push('');

        section.matrix.columns.forEach((col, colIx) => {
            const data = [];

            if (section.type === FormQuestionType.MatrixCheckbox) {
                data.push(
                    submissions.reduce((total, s) => {
                        if (this.includeDraftResponses) {
                            return (
                                total +
                                s.results.filter(
                                    r => r.id === section.id && (r.value as string[])[this.matrixRowIndex].includes(col.id),
                                ).length
                            );
                        }

                        return (
                            total +
                            s.submittedResults.filter(
                                r => r.id === section.id && (r.value as string[])[this.matrixRowIndex].includes(col.id),
                            ).length
                        );
                    }, 0),
                );
            } else {
                data.push(
                    submissions.reduce((total, s) => {
                        if (this.includeDraftResponses) {
                            return (
                                total +
                                s.results.filter(
                                    r => r.id === section.id && (r.value as string[])[this.matrixRowIndex] === col.id,
                                ).length
                            );
                        }

                        return (
                            total +
                            s.submittedResults.filter(
                                r => r.id === section.id && (r.value as string[])[this.matrixRowIndex] === col.id,
                            ).length
                        );
                    }, 0),
                );
            }

            datasets.push({
                label: col.text,
                data,
                backgroundColor: backgroundColor[colIx],
            });
        });

        const ctx = this.canvasEl.nativeElement.getContext('2d');
        const data: ChartData<'bar', number[], string> = {
            labels,
            datasets,
        };

        const config: ChartConfiguration = {
            data,
            type: 'bar',

            options: {
                responsive: false,
                maintainAspectRatio: false,

                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            precision: 0,
                        },
                    },
                },
                plugins: {
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        };

        if (this.chart) {
            this.chart.destroy();
        }

        this.chart = new Chart(ctx as any, config);
    }

    updateChart() {
        if (!this.showChart) {
            return;
        }

        if (this.section?.type === FormQuestionType.Matrix || this.section?.type === FormQuestionType.MatrixCheckbox) {
            this.buildChartForMatrix();
        } else {
            this.buildChart();
        }

        this.updateChartSize();
    }

    updateChartSize() {
        if (!this.canvasEl?.nativeElement || !this.chart) {
            return;
        }

        this.chart.resize();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['section']) {
            this.renderCheck$.next();
        }

        if (changes['submissions']) {
            this.renderCheck$.next();
        }

        if (changes['includeDraftResponses']) {
            this.renderCheck$.next();
        }

        this.updateChartSize();
    }

    ngAfterViewInit() {
        this.afterViewInit = true;
        this.renderCheck$.next();
    }

    ngOnDestroy(): void {
        this.isAlive$.next();
        this.isAlive$.complete();

        if (this.chart) {
            this.chart.destroy();
        }
    }
}
