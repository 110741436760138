import { Inject, Injectable } from '@angular/core';
import {
    ApplicationInsights,
    IEventTelemetry,
    IPageViewTelemetry,
    IExceptionTelemetry,
    IMetricTelemetry,
    IDependencyTelemetry,
} from '@microsoft/applicationinsights-web';
import { ICustomProperties } from '@microsoft/applicationinsights-core-js';
import { APP_ENVIRONMENT } from '@app/shared/tokens';
import { Environment } from '@app/shared/models';

@Injectable({
    providedIn: 'root',
})
export class ApplicationInsightsService {
    private appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: this.env.instrumentationKey,
            appId: this.env.appId,
        },
    });

    constructor(@Inject(APP_ENVIRONMENT) private env: Environment) {
        this.appInsights.loadAppInsights();
    }

    trackEvent(event: IEventTelemetry, prop?: ICustomProperties) {
        event.properties = event.properties || {};

        if (!event.properties['applicationId']) {
            event.properties['applicationId'] = this.env.appId;
        }

        if (this.env.insightsEnabled) {
            this.appInsights.trackEvent({ ...event }, { ...prop });
        }
    }

    trackPageView(event: IPageViewTelemetry) {
        event.properties = event.properties || {};

        if (!event.properties['applicationId']) {
            event.properties['applicationId'] = this.env.appId;
        }

        if (this.env.insightsEnabled) {
            this.appInsights.trackPageView({ ...event });
        }
    }

    trackMetric(event: IMetricTelemetry, prop?: ICustomProperties) {
        event.properties = event.properties || {};

        if (!event.properties['applicationId']) {
            event.properties['applicationId'] = this.env.appId;
        }

        if (this.env.insightsEnabled) {
            this.appInsights.trackMetric({ ...event }, { ...prop });
        }
    }

    trackDependency(event: IDependencyTelemetry) {
        event.properties = event.properties || {};

        if (!event.properties['applicationId']) {
            event.properties['applicationId'] = this.env.appId;
        }

        if (this.env.insightsEnabled) {
            this.appInsights.trackDependencyData({ ...event });
        }
    }

    trackException(ex: IExceptionTelemetry) {
        ex.properties = ex.properties || {};

        if (!ex.properties['applicationId']) {
            ex.properties['applicationId'] = this.env.appId;
        }

        if (this.env.insightsEnabled) {
            this.appInsights.trackException({ ...ex });
        }
    }

    setAuthenticationContext(userId: string) {
        this.appInsights.setAuthenticatedUserContext(userId, undefined, true);
    }

    clearAuthenticationContext() {
        this.appInsights.clearAuthenticatedUserContext();
    }
}
