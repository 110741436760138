import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScheduledEvent } from '@app/data/models';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './submission-complete-dialog.component.html',
    styleUrls: ['./submission-complete-dialog.component.scss'],
})
export class SubmissionCompleteDialogComponent {
    get showCloseButton() {
        return this.data.showCloseButton || false;
    }

    get scheduledEvents(): ScheduledEvent[] {
        return this.data.scheduledEvents || [];
    }

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
