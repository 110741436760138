<dl class="link-entry" fxFlex *ngIf="intakeForms | any; else noSubmissions">
    <ng-container *ngFor="let submission of intakeForms">
        <ng-container *ngIf="submission.submissionKey">
            <dt class="submission-date">Submission Date</dt>
            <dd>
                {{ submission.submissionKey | submissionDate | formatDate }}
            </dd>
        </ng-container>
        <ng-container *ngFor="let q of submission.questions; let ix = index">
            <dt>
                {{ q.question }}
            </dt>
            <dd>{{ q.answer }}</dd>
        </ng-container>
    </ng-container>
</dl>

<ng-template #noSubmissions> No Submissions </ng-template>
