<section *ngIf="path !== null" class="container">
    <ui-breadcrumb [path]="path" [nodes]="nodes" (selectNode)="setRoot($event)"></ui-breadcrumb>
</section>

<div *ngIf="!isLoading && !(nodes | any)" class="container no-content mat-elevation-z9">
    There is currently no content available to you.
</div>
<mat-tree *ngIf="nodeDataSource.data.length > 0" [dataSource]="nodeDataSource" [treeControl]="treeControl">
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding [matTreeNodePaddingIndent]="24">
        <ng-container
            *ngTemplateOutlet="nodeTemplate !== null ? nodeTemplate : branchTemplate; context: { $implicit: node, data: nodeTemplateContext }"
        >
        </ng-container>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding [matTreeNodePaddingIndent]="24">
        <ng-container [ngSwitch]="toggleMode">
            <ng-container *ngSwitchCase="ToggleMode.ScopeToNode">
                <button mat-icon-button matTreeNodeToggle (click)="toggleNode(node)">
                    <mat-icon class="mat-icon-rtl-mirror">
                        {{ isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                    </mat-icon>
                </button>
                <mat-icon [ngClass]="node.data | nodeColour">{{ node.data | nodeTypeIcon }}</mat-icon>
                <div
                    class="node-name has-children"
                    [ngClass]="{ 'is-expanded': isExpanded(node) }"
                    (click)="setRoot(node.data); $event.stopPropagation()"
                >
                    <span>{{ node.name }}</span>
                </div>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <button mat-icon-button matTreeNodeToggle (click)="toggleNode(node)">
                    <mat-icon class="mat-icon-rtl-mirror">
                        {{ isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                    </mat-icon>
                </button>
                <mat-icon [ngClass]="node.data | nodeColour">{{ node.data | nodeTypeIcon }}</mat-icon>
                <div
                    class="node-name has-children"
                    (click)="setRoot(node.data); $event.stopPropagation()"
                    [ngClass]="{ 'is-expanded': isExpanded(node) }"
                    matTreeNodeToggle
                >
                    <span>{{ node.name }}</span>
                </div>
                <ng-container *ngTemplateOutlet="labelTemplate; context: { node: node }"></ng-container>
            </ng-container>
        </ng-container>

        <ng-container *ngTemplateOutlet="actionsTemplate; context: { node: node }"></ng-container>
    </mat-tree-node>
</mat-tree>

<mat-spinner *ngIf="isLoading"></mat-spinner>

<ng-template #branchTemplate let-node>
    <div class="indent"></div>
    <mat-icon [ngClass]="node.data | nodeColour">{{ node.data | nodeTypeIcon }}</mat-icon>
    <a class="node-name" *ngIf="node.data.type !== NodeType.Node" (click)="onViewNode(node.data)">
        <span>{{ node.name }}</span>
    </a>
    <ng-container *ngTemplateOutlet="labelTemplate; context: { node: node }"></ng-container>
    <time *ngIf="node.data.duration">&nbsp;({{ node.data.duration }})</time>
    <ng-container *ngTemplateOutlet="actionsTemplate; context: { node: node }"></ng-container>
</ng-template>
