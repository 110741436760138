<mat-card>
    <!-- <mat-card-title *ngIf="showTitle">{{ title }}</mat-card-title> -->
    <mat-card-content>
        <ui-rich-text-view [content]="model?.title"></ui-rich-text-view>
        <ng-container *ngIf="payments && payments | any; else makePayment">
            <mat-list>
                <ng-container *ngFor="let payment of payments">
                    <mat-list-item>
                        <mat-icon [ngClass]="isPaymentComplete(payment) ? 'complete' : null" matListItemIcon>{{
                            isPaymentComplete(payment) ? 'credit_score' : 'payment'
                        }}</mat-icon>
                        <div matListItemTitle>{{ payment.productName }} ({{ payment.productCode }})</div>
                        <div matListItemLine>{{ payment.amount | currency : payment.merchantCurrency }}</div>
                        <div matListItemLine>{{ payment.status | paymentStatus }}</div>
                        <ng-container *ngIf="isPaymentComplete(payment)">
                            <div matListItemLine>Sold To: {{ payment.payerName }} ({{ payment.payerEmail }})</div>
                        </ng-container>
                        <ng-container *ngIf="isPaymentRefunded(payment) || isPaymentComplete(payment)">
                            <div matListItemLine>Txn: {{ payment.transactionId }}</div>
                            <div matListItemLine>
                                <small>{{ payment.paymentDate | formatDate }}</small>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="payment.paymentMethod === PaymentMethod.Stripe && isPaymentPending(payment)">
                            <button
                                mat-raised-button
                                matListItemLine
                                [disabled]="isDisabled"
                                color="primary"
                                (click)="onRetryPayment(payment)"
                            >
                                Continue Payment<mat-icon>open_in_new</mat-icon>
                            </button>
                        </ng-container>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                </ng-container>
                <ng-container *ngIf="shouldDisplayPending">
                    <mat-list-item>
                        <mat-icon matListItemIcon></mat-icon>
                        <div matListItemTitle>Loading. Please wait</div>
                        <div matListItemLine>This may take up to several seconds.</div>
                        <div class="progress" matListItemLine>
                            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                        </div>
                    </mat-list-item>
                </ng-container>

            </mat-list>
            <ui-rich-text-view *ngIf="hasSuccessMessage && isTransactionComplete()" [content]="message"></ui-rich-text-view>
            <ng-container
                *ngIf="
                    !isTransactionPending() && !isTransactionComplete() && model?.paymentProvider === PaymentProvider.Paypal
                "
                [ngTemplateOutlet]="makePayment"
            >
            </ng-container>
        </ng-container>
    </mat-card-content>
    <mat-card-actions align="end">
        <button
            *ngIf="isTransactionComplete() && showLink"
            mat-raised-button
            [disabled]="isJoiningMeeting"
            color="primary"
            (click)="onJoinMeeting()"
        >
            Join Zoom Meeting<mat-icon>video_call</mat-icon>
        </button>
    </mat-card-actions>
</mat-card>
<ng-template #makePayment>
    <button mat-raised-button [disabled]="isDisabled" color="primary" (click)="onPurchaseTicket()">Pay Now</button>
</ng-template>
