<mat-action-list>
    <mat-list-item
        class="session-group"
        [ngClass]="{ active: submission.isActive }"
        (click)="onSessionGroupSelected(submission)"
        *ngFor="let submission of submissions"
        ><div matListItemAvatar class="avatar" *ngIf="submission.isSubmitted">
            <mat-icon class="no-location">check_circle</mat-icon>
        </div>
        <time matListItemLine class="small mute">
            {{ submission.createdDate | formatDate }}
        </time>
        <time *ngIf="submission.isSubmitted" matListItemLine class="small mute">
            {{ submission.submissionDate | formatDate }}
        </time>
    </mat-list-item>
</mat-action-list>
