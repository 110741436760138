import { Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';

@Directive({
    selector: '[uiPreview]',
})
export class PreviewDirective implements OnDestroy {
    private previewText: any;

    @Input('uiPreview') set showPreview(value: boolean | string) {
        if (value !== undefined) {
            this.renderer.setStyle(this.previewText, 'display', value ? 'block' : 'none');
        } else {
            this.renderer.setStyle(this.previewText, 'display', 'none');
        }
    }

    constructor(private el: ElementRef, private renderer: Renderer2) {
        this.renderer.setStyle(this.el.nativeElement, 'position', 'relative');

        this.previewText = this.renderer.createElement('span');
        this.renderer.appendChild(this.el.nativeElement, this.previewText);
        this.renderer.setProperty(this.previewText, 'textContent', 'User Preview');

        this.renderer.setStyle(this.previewText, 'position', 'absolute');
        this.renderer.setStyle(this.previewText, 'top', '19px');
        this.renderer.setStyle(this.previewText, 'right', '-26px');
        this.renderer.setStyle(this.previewText, 'transform', 'rotate(45deg)');
        this.renderer.setStyle(this.previewText, 'background', '#000');
        this.renderer.setStyle(this.previewText, 'color', '#fff');
        this.renderer.setStyle(this.previewText, 'padding', '2px 5px');
        this.renderer.setStyle(this.previewText, 'font-size', '12px');
        this.renderer.setStyle(this.previewText, 'width', '100px');
        this.renderer.setStyle(this.previewText, 'text-align', 'center');
        this.renderer.setStyle(this.previewText, 'z-index', '1');
        this.renderer.setStyle(this.previewText, 'clip-path', 'polygon(21px 0, calc(100% - 21px) 0, 100% 100%, 0% 100%)');
    }

    ngOnDestroy() {
        this.renderer.removeChild(this.el.nativeElement, this.previewText);
    }
}
