<mat-card>
    <mat-card-content>
        <mat-card-title>{{ model?.topic }}</mat-card-title>
        <mat-list dense role="list" *ngIf="model">
            <mat-list-item role="listitem">Email: {{ model.email }}</mat-list-item>
            <mat-list-item role="listitem"
                >Join Time: {{ model.joinTime ? (model.joinTime | formatDate) : '--' }}</mat-list-item
            >
            <mat-list-item role="listitem" *ngIf="!model.isInSession">
                <span matListItemTitle>Leave Time: {{ model.leaveTime ? (model.leaveTime | formatDate) : '--' }}</span>
            </mat-list-item>
            <mat-list-item role="listitem">Total Duration: {{ model.durationInSeconds | elapsedTime }}</mat-list-item>
        </mat-list>

    </mat-card-content>
</mat-card>
