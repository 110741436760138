import { Pipe, PipeTransform } from '@angular/core';

import { NodeType } from '../enums';

@Pipe({
    name: 'nodeType',
})
export class NodeTypePipe implements PipeTransform {
    transform(value: any): string {
        switch (value) {
            case NodeType.Node:
                return 'Folder';
            case NodeType.Html:
                return 'Html';
            case NodeType.Audio:
                return 'Audio';
            case NodeType.Text:
                return 'Text';
            case NodeType.Video:
                return 'Video';
            case NodeType.File:
                return 'File';
            default:
                return 'Invalid Node Type';
        }
    }
}
