import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TrackByFunction,
} from '@angular/core';

import { AsyncSubject, BehaviorSubject, combineLatest, filter, fromEvent, tap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FormSection, ScheduledEvent } from '@app/data/models';
import { DestroyService } from '@app/shared/services';

declare const Calendly: any;

@Component({
    selector: 'ui-form-section-booking',
    templateUrl: './form-section-booking.component.html',
    styleUrls: ['./form-section-booking.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [DestroyService],
})
export class FormSectionBookingComponent implements OnInit, AfterViewInit, OnChanges {
    ids: string[] = [];
    afterViewInit$: AsyncSubject<void> = new AsyncSubject();
    changes$: BehaviorSubject<SimpleChanges> = new BehaviorSubject({} as SimpleChanges);

    hasBooked = false;

    get showTitle() {
        return this.model && this.model.title;
    }

    get title() {
        return this.model?.title;
    }

    get url() {
        return this.model?.bookingUrl || '';
    }

    @Input() model: FormSection | null = null;
    @Input() scheduledEvent: ScheduledEvent | null = null;

    @Output() readonly valueChanged: EventEmitter<any> = new EventEmitter();
    @Output() readonly loadEvent: EventEmitter<string> = new EventEmitter();

    constructor(private cd: ChangeDetectorRef, private destroy$: DestroyService) {}

    trackBy = (): TrackByFunction<string> => {
        return (ix, model) => model;
    };

    generateId() {
        this.ids = [];
        this.ids.push(`form-section-booking-${Math.floor(Math.random() * 1000000)}`);
    }

    loadBookingUrl() {
        this.generateId();
        this.cd.detectChanges();

        setTimeout(() => {
            Calendly.initInlineWidget({
                url: this.url,
                parentElement: document.getElementById(this.ids[0]),
                prefill: {},
                utm: {},
            });
        });
    }

    hasCalendlyResult(section: FormSection) {
        try {
            const model = JSON.parse(section.value as string);
            const uri = model.payload?.event?.uri;

            return uri ? true : false;
        } catch (error) {}

        return false;
    }

    ngOnInit(): void {
        fromEvent(window, 'message')
            .pipe(
                takeUntil(this.destroy$),
                filter((e: any) => e.origin === 'https://calendly.com' && e.data.event === 'calendly.event_scheduled'),
                tap((e: MessageEvent) => {
                    this.valueChanged.emit({
                        id: this.model?.id,
                        value: JSON.stringify(e.data),
                    });

                    this.hasBooked = true;
                    this.loadEvent.emit(this.model?.id);
                }),
            )
            .subscribe();

        combineLatest([this.afterViewInit$, this.changes$])
            .pipe(
                takeUntil(this.destroy$),
                filter(() => this.model !== null),
                tap(() => {
                    if (this.hasCalendlyResult(this.model as FormSection)) {
                        this.hasBooked = true;
                        if (!this.scheduledEvent) {
                            this.loadEvent.emit(this.model?.id);
                        }
                    } else if (!this.hasBooked) {
                        this.loadBookingUrl();
                    }
                }),
            )
            .subscribe();
    }

    ngAfterViewInit(): void {
        this.afterViewInit$.next();
        this.afterViewInit$.complete();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.changes$.next(changes);
    }
}
