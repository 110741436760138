import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';

import { PaymentModel } from '@app/data/models';
import { Environment } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';
import { loadStripe, PaymentIntent, Stripe, StripeElements, StripePaymentElement } from '@stripe/stripe-js';

@Component({
    selector: 'shared-stripe-payment-dialog',
    templateUrl: `./stripe-payment-dialog.component.html`,
    styleUrls: [`./stripe-payment-dialog.component.scss`],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StripePaymentDialogComponent implements OnInit, OnDestroy {
    form = this.fb.group({
        name: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
    });

    isLoading = false;
    error: string | null = null;
    checkout: Stripe | null = null;
    elements: StripeElements | null = null;
    host: StripePaymentElement | null = null;

    get email() {
        return this.form.get('email') as AbstractControl;
    }

    get name() {
        return this.form.get('name') as AbstractControl;
    }

    get isFirstStep() {
        if (this.stepper) {
            return this.stepper.selectedIndex === 0;
        }

        return true;
    }

    @ViewChild('stepper') stepper: MatStepper;
    @ViewChild('payment') paymentEl: ElementRef<HTMLDivElement>;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: PaymentModel,
        @Inject(APP_ENVIRONMENT) private env: Environment,
        private matDialogRef: MatDialogRef<StripePaymentDialogComponent>,
        private fb: FormBuilder,
        private cd: ChangeDetectorRef,
    ) {}

    async ngOnInit() {
        const clientSecret = this.data.checkoutSessionClientSecret as string;
        this.checkout = await loadStripe(this.env.stripePublishableKey as string);

        if (!this.checkout) {
            return;
        }

        // const name = this.store.selectSnapshot(HomeState.getName());

        // this.form.patchValue({
        //     name,
        // });

        this.elements = this.checkout.elements({ clientSecret, loader: 'auto' });

        const result = await this.checkout.retrievePaymentIntent(clientSecret);

        if (result.paymentIntent && this.checkPaymentStatus(result.paymentIntent)) {
            return;
        }

        this.host = this.elements.create('payment', {
            fields: {
                billingDetails: 'auto',
            },
            defaultValues: {
                billingDetails: {
                    name: this.form.get('name')?.value || '',
                    email: this.form.get('email')?.value || '',
                },
            },
        });

        this.host.mount(this.paymentEl.nativeElement);
    }

    onNavigateToStepIndex(index: number) {
        this.stepper.selectedIndex = index;
    }

    async onSubmit() {
        this.error = null;

        if (!this.checkout || !this.elements) {
            return;
        }

        const { error } = await this.elements.submit();

        if (!error) {
            await this.confirmPayment();
        }
    }

    async confirmPayment() {
        if (!this.checkout || !this.elements) {
            return;
        }

        this.isLoading = true;
        this.cd.detectChanges();

        const result = await this.checkout.confirmPayment({
            elements: this.elements,
            clientSecret: this.data.checkoutSessionClientSecret as string,
            confirmParams: {
                return_url: window.location.href.toString(),
                payment_method_data: {
                    billing_details: {
                        name: this.form.get('name')?.value || '',
                        email: this.form.get('email')?.value || '',
                    },
                },
                receipt_email: this.form.get('email')?.value || '',
            },
            redirect: 'if_required',
        });

        if (result.error) {
            console.error(result.error);
            this.error = result.error.message?.toString() || '';
        } else {
            const { paymentIntent } = await this.checkout.retrievePaymentIntent(
                this.data.checkoutSessionClientSecret as string,
            );

            if (!paymentIntent) {
                return;
            }

            this.checkPaymentStatus(paymentIntent);
        }

        this.isLoading = false;
        this.cd.detectChanges();
    }

    checkPaymentStatus(paymentIntent: PaymentIntent) {
        switch (paymentIntent.status) {
            case 'succeeded':
                // message.innerText = 'Success! Payment received.';
                this.matDialogRef.close(true);
                return true;

            case 'processing':
                // message.innerText = "Payment processing. We'll update you when payment is received.";
                break;

            case 'requires_payment_method':
                // message.innerText = 'Payment failed. Please try another payment method.';
                // Redirect your user back to your payment page to attempt collecting
                // payment again
                break;

            default:
                // message.innerText = 'Something went wrong.';
                break;
        }

        return false;
    }

    ngOnDestroy(): void {
        if (this.host) {
            this.host.destroy();
            this.host = null;

            this.elements = null;
            this.checkout = null;
        }
    }
}
