<mat-card>
    <mat-card-header>
        <mat-card-title-group>
            <mat-card-title>{{ link?.url }} ({{ category }})</mat-card-title>
        </mat-card-title-group>
    </mat-card-header>
    <mat-card-content>
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.name }}
                    <mat-icon
                        class="webinar-icon"
                        *ngIf="element.registrantInfo"
                        [ngClass]="{
                            'has-registered': element.registrantInfo.hasRegistered,
                            'has-joined': element.registrantInfo.hasJoined
                        }"
                        cdkOverlayOrigin
                        #origin="cdkOverlayOrigin"
                        svgIcon="zoom_logo"
                    ></mat-icon>
                </td>
            </ng-container>
            <ng-container matColumnDef="joinTime">
                <th mat-header-cell *matHeaderCellDef>Join Time</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.registrantInfo.joinTime ? (element.registrantInfo.joinTime | formatDate) : '--' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="leaveTime">
                <th mat-header-cell *matHeaderCellDef>Leave Time</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.registrantInfo.leaveTime ? (element.registrantInfo.leaveTime | formatDate) : '--' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="durationInSeconds">
                <th mat-header-cell *matHeaderCellDef>Duration</th>
                <td mat-cell *matCellDef="let element">{{ element.registrantInfo.durationInSeconds | elapsedTime }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </mat-card-content>
</mat-card>
