import { Component, Inject } from '@angular/core';
import { DebugState } from '@app/data/state';
import { Environment } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';
import { Store } from '@ngxs/store';

@Component({
    selector: 'shared-build-info',
    template: `
        <div *ngIf="show$ | async" class="build-info">
            <span>{{ env.buildVersion }} ({{ env.buildDate | formatDate }})</span>
        </div>
    `,
    styles: [
        `
            .build-info {
                pointer-events: none;
                opacity: 0.5;
                position: absolute;
                bottom: 0;
                right: 0;
                font-size: 12px;
                z-index: 999;
            }
        `,
    ],
})
export class BuildInfoComponent {
    show$ = this.store.select(DebugState.isEnabled());

    constructor(@Inject(APP_ENVIRONMENT) protected env: Environment, private store: Store) {}
}
