import { ChangeDetectionStrategy, Component } from '@angular/core';

import { Store } from '@ngxs/store';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <mat-dialog-content>
            <p>Please wait while we check your browser...</p>
            <mat-progress-bar mode="query"></mat-progress-bar>
        </mat-dialog-content>
    `,
    styles: [
        `
            mat-icon {
                vertical-align: sub;
            }
        `,
    ],
})
export class BrowserCheckDialogComponent {

    constructor(private store: Store) {}
}
