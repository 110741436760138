import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ClipboardModel, LinkSessionEntry, LinkSessionSummary, SessionEntryGroup } from '@app/data/models';
import { ErrorCodes, SessionStatus } from '@app/shared/enums';
import { getErrorCodeMessage, isObject } from '@app/shared/util';

@Component({
    selector: 'ui-session-history',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './session-history.component.html',
    styleUrls: ['./session-history.component.scss'],
})
export class SessionHistoryComponent {
    SessionStatus: typeof SessionStatus = SessionStatus;
    ErrorCodes: typeof ErrorCodes = ErrorCodes;

    @Input() sessions: SessionEntryGroup[] | null = null;
    @Input() summary: LinkSessionSummary | null = null;
    @Input() requiresApproval: boolean | null = false;

    @Output() readonly copyTextToClipboard: EventEmitter<ClipboardModel> = new EventEmitter();
    @Output() readonly selectIpAddress: EventEmitter<string> = new EventEmitter();
    @Output() readonly selectDevice: EventEmitter<string> = new EventEmitter();

    onCopyTextToClipboard(text: string) {
        this.copyTextToClipboard.emit({ text, message: 'Copied to clipboard' });
    }

    onSelectIpAddress(ipAddress: string) {
        this.selectIpAddress.emit(ipAddress);
    }

    onSelectDevice(device: string) {
        this.selectDevice.emit(device);
    }

    onToggleVisibility(group: SessionEntryGroup) {
        group.isVisible = !group.isVisible;
    }

    getEntryStatus(model: LinkSessionEntry): string {
        switch (model.status) {
            case SessionStatus.Active:
                if (model.errorCode) {
                    if (model.errorCode === ErrorCodes.LinkSubmissionPending && !this.requiresApproval) {
                        return `Registration submission has been received`;
                    }

                    return `${getErrorCodeMessage(model.errorCode, model.meta as any)}`;
                }

                return model.isInformational ? 'Login Success' : 'Password Activated';
            case SessionStatus.Inactive:
                if (model.errorCode === ErrorCodes.LinkSessionInactivity) {
                    return `User session was blocked due to inactivity`;
                }

                return 'User session was blocked';
            case SessionStatus.Failed:
                if (model.errorCode) {
                    return `${getErrorCodeMessage(model.errorCode, model.meta as any)}`;
                }

                return 'Error';
            default:
                return '';
        }
    }

    getEntrySubmissionFailure(meta: any): string {
        try {
            if (isObject(meta)) {
                return `${(meta as any).question} '${(meta as any).answer}'`;
            }
            // eslint-disable-next-line no-empty
        } catch {}

        return meta;
    }
}
