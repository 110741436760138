<mat-card [ngClass]="{ readonly: readonly }">
    <mat-card-header>
        <mat-card-title *ngIf="showTitle">{{ title }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ui-rich-text-view [content]="model?.description"></ui-rich-text-view>

        <div>
            <button mat-raised-button [disabled]="isDownloading" color="primary" (click)="onDownload().subscribe()">
                <mat-icon *ngIf="!isDownloading">get_app</mat-icon>
                <mat-spinner *ngIf="isDownloading" diameter="15"></mat-spinner>
                Download
            </button>
        </div>
        <div class="error" *ngIf="isRequired && !hasDownloaded">This file must be downloaded to complete the form</div>
    </mat-card-content>
</mat-card>
