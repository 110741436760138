import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Environment } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';

@Injectable({ providedIn: 'root' })
export class ContentService {
    constructor(@Inject(APP_ENVIRONMENT) private env: Environment, private http: HttpClient) {}

    getBinary(nodeId: string): Observable<HttpResponse<ArrayBuffer>> {
        return this.http.post(
            `${this.env.serverUrl}/nodes/binary`,
            { nodeId },
            {
                observe: 'response',
                responseType: 'arraybuffer',
            },
        );
    }
}
