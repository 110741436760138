import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Inject,
    OnChanges,
    OnDestroy,
    SimpleChanges,
} from '@angular/core';
import { APP_TIPTAP_EDITOR_CONFIG } from '@app/shared/tokens';
import { Editor, EditorOptions } from '@tiptap/core';

export interface TextSectionModel {
    id: string;

    title: string;
    description: string;
}

@Component({
    selector: 'ui-form-section-text',
    templateUrl: './form-section-text.component.html',
    styleUrls: ['./form-section-text.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSectionTextComponent {

    get showTitle() {
        return this.model && this.model.title;
    }

    get title() {
        return this.model?.title;
    }

    @Input() model: TextSectionModel | null = null;
}
