import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'includes',
})
export class IncludesPipe implements PipeTransform {
    transform(arr: Array<any>|null, value: unknown): boolean {
        if (!arr) return false;

        return arr.includes(value);
    }
}
