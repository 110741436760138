import { ChangeDetectionStrategy, Component } from '@angular/core';

import { AngularNodeViewComponent } from 'ngx-tiptap';

import { LOADING_SPINNER } from '../../util';

@Component({
    selector: 'shared-tiptap-image-container',
    templateUrl: './tiptap-image-container.component.html',
    styleUrls: ['./tiptap-image-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TiptapImageContainerComponent extends AngularNodeViewComponent {
    id = performance.now().toString();

    get size(): 'small' | 'medium' | 'large' {
        return this.node.attrs['size'];
    }

    get src() {
        return this.node.attrs['src'] || LOADING_SPINNER;
    }

    get title() {
        return this.node.attrs['title'] || '';
    }

    get styles() {
        const textAlign = this.node.attrs['textAlign'];

        return {
            'text-align': textAlign,
        };
    }

    getClasses() {
        return {
            'custom-image-small': this.size === 'small',
            'custom-image-medium': this.size === 'medium',
            'custom-image-large': this.size === 'large',
        };
    }
}
