export class Clear {
    static readonly type = '[Debug] Clear';
}

export class Add {
    static readonly type = '[Debug] Add';
    constructor(public message: string) {}
}

export class CopyLogs {
    static readonly type = '[Debug] Copy Logs';
}
