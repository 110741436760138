import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';

import { Observable } from 'rxjs';

import { NotificationType } from '@app/shared/enums';
import { Action, createSelector, State, StateContext, Store } from '@ngxs/store';
import { ComponentType, IndividualConfig, ToastrService } from 'ngx-toastr';

import { NotificationActions } from '../actions';
import { ProgressNotificationComponent } from '../components';

interface Notification {
    message: string;
    warnings?: string[];
    error?: any;
    type: NotificationType;
    config?: Partial<MatSnackBarConfig>;
    allowDismiss?: boolean;
    component?: ComponentType<any>;
}

export interface StateModel {
    queue: Notification[];
    isQueueProcessing: boolean;
}

const DEFAULT_CONFIG: Partial<IndividualConfig<any>> = {
    progressBar: true,
    timeOut: 5000,
    positionClass: 'toast-bottom-right',
};

@State<StateModel>({
    name: 'notifications',
    defaults: {
        queue: [],
        isQueueProcessing: false,
    },
})
@Injectable({
    providedIn: 'root',
})
export class NotificationsState {
    private progressRef: MatSnackBarRef<ProgressNotificationComponent> | null = null;

    static isQueueProcessing() {
        return createSelector([NotificationsState], (state: StateModel) => state.isQueueProcessing);
    }

    static selectQueue() {
        return createSelector([NotificationsState], (model: StateModel) => model.queue);
    }

    static selectCurrentItem() {
        return createSelector([NotificationsState.selectQueue()], (queue: Notification[]) =>
            queue && queue.length > 0 ? queue[0] : null,
        );
    }

    constructor(private snack: MatSnackBar, private toastr: ToastrService) {}

    @Action(NotificationActions.Success)
    success(ctx: StateContext<StateModel>, action: NotificationActions.Success): void | Observable<any> {
        const config = action.config || {};

        this.toastr.success(action.message, '', {
            ...DEFAULT_CONFIG,
            ...config,
        });
    }

    @Action(NotificationActions.Info)
    info(ctx: StateContext<StateModel>, action: NotificationActions.Info): void | Observable<any> {
        const config = action.config || {};
        this.toastr.info(action.message, '', {
            ...DEFAULT_CONFIG,
            ...config,
        });
    }

    @Action(NotificationActions.Error)
    error(ctx: StateContext<StateModel>, { error, message }: NotificationActions.Error): void | Observable<any> {
        this.toastr.error(message, 'Error', {
            disableTimeOut: true,
            closeButton: true,
            tapToDismiss: true,
            positionClass: 'toast-bottom-right',
        });
    }

    @Action(NotificationActions.Acknowledge)
    acknowledge(
        ctx: StateContext<StateModel>,
        { message, allowDismiss }: NotificationActions.Acknowledge,
    ): void | Observable<any> {
        this.toastr.info(message, '', {
            progressBar: true,
            disableTimeOut: allowDismiss,
            positionClass: 'toast-bottom-right',
            closeButton: true,
        });
    }

    @Action(NotificationActions.Warning)
    warning(
        ctx: StateContext<StateModel>,
        { message, warnings, allowDismiss }: NotificationActions.Warning,
    ): void | Observable<any> {
        this.toastr.warning(message, 'Warning', {
            disableTimeOut: allowDismiss,
            closeButton: true,
            tapToDismiss: false,
            positionClass: 'toast-bottom-right',
        });
    }

    @Action(NotificationActions.Progress)
    progress(
        ctx: StateContext<StateModel>,
        { message, progress, isComplete }: NotificationActions.Progress,
    ): void | Observable<any> {
        if (!this.progressRef) {
            this.progressRef = this.snack.openFromComponent(ProgressNotificationComponent, { data: null });
        }

        this.progressRef.instance.text = message;
        this.progressRef.instance.progress = progress === 100 ? 99 : Math.ceil(progress);
        this.progressRef.instance.cd.detectChanges();

        if (isComplete) {
            this.progressRef.instance.progress = 100;
            this.progressRef.instance.cd.detectChanges();

            setTimeout(() => {
                this.progressRef?.dismiss();
                this.progressRef = null;
            }, 2000);
        }
    }
}
