import { Environment } from '@app/shared/models';

export const environment: Environment = {
    appId: 'admin',
    production: true,
    instrumentationKey: '',
    searchIndexQueueName: 'searchindex',

    appUrl: location.origin,

    cdnUrl: 'https://cdn.shareablelink.online',
    rapidApiUrl: 'https://api.shareablelink.online/func-api-auth',
    serverUrl: `https://api.shareablelink.online`,
    proxyServerUrl: `https://api.shareablelink.online`,
    fpEndpointUrl: `https://shareablelink.online`,

    activityReportInSec: 60,
    inactivityTimeoutInSec: 604800, //7 days
    debug: false,
    autoUpdate: true,
    insightsEnabled: true,

    blobStorageUrl: '',
    privateContainerName: '',
    publicContainerName: '',

    sourceStorageSasUri: '',
    destinationStorageSasUri: '',

    buildVersion: '1.0.25',
    buildDate: '2024-09-19T00:14:04+00:00',
    buildCommit: '67a0a4829502569c6c45bd5623a1f7b2eb1a722f'
};
