import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'pad',
})
export class PadPipe implements PipeTransform {
    transform(value: number, amount = 2): string {
        return value.toString().padStart(amount, '0');
    }
}
