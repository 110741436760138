import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'ui-log-viewer',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: `./log-viewer.component.html`,
    styleUrls: [`./log-viewer.component.scss`],
})
export class LogViewerComponent {
    @Input() logs: string[] | null = [];
    @Output() readonly copy: EventEmitter<void> = new EventEmitter();
    @Output() readonly clear: EventEmitter<void> = new EventEmitter();

    onClear() {
        this.clear.emit();
    }

    onCopy() {
        this.copy.emit();
    }
}
