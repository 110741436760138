import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-calendly',
    template: ``,
})
export class CalendlyComponent implements OnInit {
    constructor(private route: ActivatedRoute) {}

    ngOnInit() {
        const code = this.route.snapshot.queryParamMap.get('code');

        if (code) {
            window.opener.postMessage({ type: 'calendly', action: 'calendly.SetAccessCode', code }, window.origin);
        }
    }
}
