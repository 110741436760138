import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';

import { interval, Subject } from 'rxjs';
import { filter, finalize, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { ChecklistModel } from '@app/data/models';
import { SECONDS_1 } from '@app/shared/constants';
import { APP_TIPTAP_EDITOR_CONFIG } from '@app/shared/tokens';
import { Editor, EditorOptions } from '@tiptap/core';
import TaskItem from '@tiptap/extension-task-item';
import Tasklist from '@tiptap/extension-task-list';

@Component({
    selector: 'ui-checklist',
    templateUrl: './checklist.component.html',
    styleUrls: ['./checklist.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistComponent implements OnInit, OnDestroy {
    private hasChanged = false;
    private isAlive$: Subject<void> = new Subject();

    contentChanged$ = new Subject<{ rowKey: string; content: string }>();
    cancelSave$ = new Subject<void>();

    editor: Editor;
    countdown = 0;
    opened = true;
    currentTemplateId: string | null = null;

    get currentChecklist() {
        return this.checklists?.find(c => c.rowKey === this.currentTemplateId);
    }

    @Input() checklists: ChecklistModel[] | null = [];
    @Output() save = new EventEmitter<{ checklist: ChecklistModel; content: string }>();

    constructor(@Inject(APP_TIPTAP_EDITOR_CONFIG) private config: EditorOptions, private cd: ChangeDetectorRef) {}

    ngOnInit(): void {
        const extensions = [...this.config.extensions];

        extensions.push(Tasklist.configure({}));

        extensions.push(
            TaskItem.configure({
                nested: true,
                onReadOnlyChecked: (node, checked) => {
                    (node.attrs as any).checked = checked;

                    this.hasChanged = true;
                    this.contentChanged$.next({ rowKey: this.currentTemplateId as string, content: this.editor.getHTML() });

                    return true;
                },
            }),
        );

        this.editor = new Editor({
            ...this.config,
            extensions,
            content: '',
        });

        this.cancelSave$
            .pipe(
                takeUntil(this.isAlive$),
                tap(() => {
                    this.hasChanged = false;
                    this.countdown = 0;
                }),
            )
            .subscribe();

        this.contentChanged$
            .pipe(
                takeUntil(this.isAlive$),
                switchMap(() => {
                    this.hasChanged = true;
                    return interval(SECONDS_1).pipe(
                        takeUntil(this.isAlive$),
                        takeUntil(this.contentChanged$),
                        takeUntil(this.cancelSave$),
                        take(4),
                        tap(v => {
                            this.countdown = 3 - v;
                            this.cd.detectChanges();
                        }),
                        filter(v => v === 3),
                        tap(() => {
                            const content = this.editor.getHTML();
                            const checklist = this.currentChecklist as ChecklistModel;
                            checklist.content = content;

                            this.save.emit({
                                checklist,
                                content,
                            });

                            this.countdown = 0;
                            this.hasChanged = false;
                        }),
                        // finalize(() => {
                        //     this.cd.detectChanges();
                        // }),
                    );
                }),
                finalize(() => {
                    if (this.hasChanged) {
                        const content = this.editor.getHTML();
                        const checklist = this.currentChecklist as ChecklistModel;
                        checklist.content = content;

                        this.save.emit({
                            checklist,
                            content,
                        });
                    }
                }),
            )
            .subscribe();
    }

    onSelectTemplate(checklist: ChecklistModel) {
        const currentChecklist = this.currentChecklist;
        if (this.hasChanged && currentChecklist && currentChecklist.rowKey !== checklist.rowKey) {
            currentChecklist.content = this.editor.getHTML();
            this.save.emit({ checklist: currentChecklist, content: currentChecklist.content });
            this.cancelSave$.next();
        }

        this.currentTemplateId = checklist.rowKey;
        this.editor.commands.setContent(checklist.content);
    }

    ngOnDestroy(): void {
        this.isAlive$.next();
        this.isAlive$.complete();

        this.cancelSave$.complete();
        this.contentChanged$.complete();

        this.editor.destroy();
    }
}