<mat-dialog-content>
    <ng-container *ngIf="error">
        <h2 *ngIf="title" [ngClass]="headerClass">{{ title }}</h2>
        <p *ngIf="showErrorMessage">{{ errorMessage }}</p>
        <mat-list *ngIf="showPendingApproval || showRegistrationCutoff || showLinkNotStarted">
            <!-- <mat-list-item *ngIf="showLinkNotStarted">Link not started</mat-list-item> -->
            <mat-list-item >
                <mat-icon matListItemIcon>info</mat-icon>
                <div *ngIf="showLinkNotStarted" matListItemLine>Link not started</div>
                <div *ngIf="showRegistrationCutoff" matListItemLine>Pre Registration cutoff</div>
                <div *ngIf="showRegistrationCutoff" matListItemLine>
                    <b>{{ formatDate(error.registrationEndDate, error.timeZone) }}</b>
                </div>
                <div *ngIf="showPendingApproval" matListItemLine class="text">
                    Submission has been received and is pending approval
                </div>
                <div *ngIf="showPendingApproval" matListItemLine class="text">
                    Check back periodically to see if you have been approved
                </div>
            </mat-list-item>
        </mat-list>
        <ng-container *ngIf="showLinkWillBegin || (showLinkBegan && (error.startDate || error.endDate))">
            <mat-divider></mat-divider>
            <mat-list>
                <mat-list-item *ngIf="showLinkWillBegin">
                    <mat-icon matListItemIcon>timer</mat-icon>
                    <div matListItemLine>Link will begin at</div>
                    <div matListItemLine class="text">
                        <b>{{ formatDate(error.startDate, error.timeZone) }}</b>
                    </div>
                    <div matListItemLine>Link ends at</div>
                    <div matListItemLine>
                        <b>{{ formatDate(error.endDate, error.timeZone) }}</b>
                    </div>
                </mat-list-item>
                <mat-list-item *ngIf="showLinkBegan && (error.startDate || error.endDate)">
                    <mat-icon matListItemIcon>timer</mat-icon>
                    <div *ngIf="error.startDate" matListItemLine>Link began at</div>
                    <div *ngIf="error.startDate" matListItemLine class="text">
                        <b>{{ formatDate(error.startDate, error.timeZone) }}</b>
                    </div>
                    <div *ngIf="error.endDate" matListItemLine>Link ends at</div>
                    <div *ngIf="error.endDate" matListItemLine>
                        <b>{{ formatDate(error.endDate, error.timeZone) }}</b>
                    </div>
                </mat-list-item>
            </mat-list>
        </ng-container>
        <mat-divider *ngIf="showMessage || (showCountdown && startDate)"></mat-divider>
        <ng-container *ngIf="showMessage">
            <mat-list>
                <mat-list-item class="message">
                    <mat-icon matListItemIcon>help</mat-icon>
                    <div matListItemLine class="text" [innerHTML]="message | safeHtml"></div>
                </mat-list-item>
            </mat-list>
        </ng-container>
    </ng-container>
    <ui-count-down *ngIf="showCountdown && startDate" [startDate]="startDate" (countDown)="onCountDown()"></ui-count-down>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button (click)="onLogout()">Logout <mat-icon>logout</mat-icon></button>
</mat-dialog-actions>
