<span *ngIf="countdown">Saving in {{ countdown }}</span>
<div fxLayout="row nowrap" fxLayoutGap="10px">
    <div class="list" fxFlex="0 0 auto">
        <button mat-mini-fab (click)="opened = !opened" style="margin: 10px">
            <mat-icon aria-label="Menu">menu</mat-icon>
        </button>
        <mat-list
            *ngIf="opened">
            <mat-list-item
                matRipple
                [ngClass]="{ selected: currentTemplateId === template.rowKey }"
                *ngFor="let template of checklists"
                (click)="onSelectTemplate(template)"
            >
                {{ template.name }}
            </mat-list-item>
        </mat-list>
    </div>
    <div fxFlex="1 1 auto" class="html-content">
        <div class="tiptap-container">
            <div class="ProseMirror" tiptap [editor]="editor"></div>
        </div>
    </div>
</div>
