<button class="close" mat-icon-button (click)="onChangeMediaPlayerState(MiniPlayerState.Hidden)">
    <mat-icon>close</mat-icon>
</button>
<shared-mini-player
    [title]="title$ | async"
    [isPlaying]="isPlaying$ | async"
    [canPlay]="canPlay$ | async"
    [position]="position$ | async"
    [duration]="duration$ | async"
    [playbackRate]="playbackRate$ | async"
    [isLoading]="isLoading$ | async"
    [canSeek]="canSeek$ | async"
    (seekMedia)="onSeek($event)"
    (playMedia)="onPlay()"
    (changePlaybackRate)="onPlaybackRate($event)"
    (pauseMedia)="onPause()"
>
</shared-mini-player>
