import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';

@Component({
    selector: 'data-progress-notification',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `<section>{{ text }} {{ progress }}%</section>`,
})
export class ProgressNotificationComponent {
    text = '';
    progress = 0;

    constructor(public cd: ChangeDetectorRef) {}
}
