import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { Environment } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';
import { Store } from '@ngxs/store';

import { AuthState } from '../state';
import { isNullOrEmpty } from '@app/shared/util';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(@Inject(APP_ENVIRONMENT) private env: Environment, private store: Store) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (
            req.url.startsWith(`${this.env.serverUrl}`) ||
            req.url.startsWith(`${this.env.proxyServerUrl}`) ||
            req.url.startsWith(`${this.env.rapidApiUrl}`)
        ) {
            return this.store.select(AuthState.getToken()).pipe(
                take(1),
                switchMap(token => {
                    const authReq = token ? this.addToken(req, token.token) : req;
                    return next.handle(authReq);
                }),
            );
        }

        return next.handle(req);
    }

    addToken(request: HttpRequest<any>, token: string | null | undefined): HttpRequest<any> {
        if (request.headers.has('Authorization') && !isNullOrEmpty(request.headers.get('Authorization'))) {
            // request already has the header set, so fast return
            return request;
        }

        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });
    }
}
