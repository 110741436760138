<h2 matDialogTitle>Submission Complete</h2>
<mat-dialog-content>
    <ng-container *ngFor="let event of scheduledEvents">
        <div>{{ event.eventName }} {{ event.startTime | date : 'short' }} - {{ event.endTime | date : 'short' }}</div>
        <a [href]="event.location?.join_Url" target="_blank" rel="nofollow" *ngIf="event.location?.join_Url">{{
            event.location?.join_Url
        }}</a>
    </ng-container>
    <ui-rich-text-view [content]="data.submissionMessage"></ui-rich-text-view>
</mat-dialog-content>
<mat-dialog-actions *ngIf="showCloseButton" align="end">
    <div class="resubmit">Would you like to update your answers?</div>
    <button mat-raised-button color="primary" [mat-dialog-close]="true">Update Answers</button>
</mat-dialog-actions>
