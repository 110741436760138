<ng-container *ngIf="isActivated" [ngSwitch]="displayAs">
    <time class="active" *ngSwitchCase="DisplayAs.RegistrationReset">
        {{ resetDisplay }} ({{ resetStartDate | formatDate }})
        <span *ngIf="!isExpired && expires">{{ expires | formatDate }}</span>
    </time>
    <time class="active" *ngSwitchCase="DisplayAs.Timer">
        <mat-icon>timer</mat-icon>{{ linkInactivityMode === LinkInactivityMode.Link ? 'Link Timer' : 'User Timer' }} ({{
            timer
        }}{{ timerUnit | timerUnit }})
        <span *ngIf="!isExpired && expires">{{ expires | formatDate }}</span>
    </time>
    <time class="expired" *ngSwitchCase="DisplayAs.Expired"><mat-icon color="warn">timer</mat-icon> Link expired for User </time>
    <time class="active" *ngSwitchCase="DisplayAs.EndDate"> End Date {{ endDate | formatDate }} </time>
</ng-container>
<ng-container *ngIf="!isActivated && isInfluencerAuthenticated"><mat-icon class="pending">timer</mat-icon> Link not activated by User </ng-container>
