import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Environment } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';

@Injectable()
export class CredentialsInterceptor implements HttpInterceptor {
    currentHost = location.host;
    proxyHost = new URL(this.env.proxyServerUrl).host;

    constructor(@Inject(APP_ENVIRONMENT) private env: Environment) {}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.startsWith(`${this.env.proxyServerUrl}`) && this.currentHost !== this.proxyHost) {
            return next.handle(
                req.clone({
                    withCredentials: true,
                }),
            );
        }
        return next.handle(req);
    }
}
