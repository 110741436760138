import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

import { MediaPlayerActions } from '@app/data/actions';
import { MediaPlayerState } from '@app/data/state';
import { MiniPlayerState } from '@app/shared/enums';
import { Store } from '@ngxs/store';

@Component({
    selector: 'shared-mini-player-container',
    templateUrl: './mini-player-container.component.html',
    styleUrls: ['./mini-player-container.component.scss'],
})
export class MiniPlayerContainerComponent {
    MiniPlayerState: typeof MiniPlayerState = MiniPlayerState;
    state: MiniPlayerState = MiniPlayerState.Hidden;

    isPlaying$ = this.store.select(MediaPlayerState.isPlaying());
    title$ = this.store.select(MediaPlayerState.getTitle());
    duration$ = this.store.select(MediaPlayerState.getDuration());
    position$ = this.store.select(MediaPlayerState.getPostition());
    canSeek$ = this.store.select(MediaPlayerState.canSeek());
    canPlay$ = this.store.select(MediaPlayerState.canPlay());
    isLoading$ = this.store.select(MediaPlayerState.isLoading());
    playbackRate$ = this.store.select(MediaPlayerState.getPlaybackRate());

    constructor(private store: Store) {}

    onPlay() {
        this.store.dispatch(new MediaPlayerActions.PlayMedia());
    }

    onPause() {
        this.store.dispatch(new MediaPlayerActions.PauseMedia());
    }

    onSeek(amount: number) {
        this.store.dispatch(new MediaPlayerActions.SeekMedia(amount));
    }

    onPlaybackRate(rate: number) {
        this.store.dispatch(new MediaPlayerActions.PlaybackRate(rate));
    }

    onChangeMediaPlayerState(state: MiniPlayerState) {
        this.store.dispatch(new MediaPlayerActions.SetMiniPlayerVisibility(state));
    }
}
