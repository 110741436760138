import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <h2 matDialogTitle><mat-icon>app_blocking</mat-icon> Device not supported</h2>
        <mat-dialog-content>
            Desktop mode is not supported on mobile. Please disable desktop mode and refresh
        </mat-dialog-content>
    `,
    styles: [
        `
            mat-icon {
                vertical-align: sub;
            }
        `,
    ],
})
export class DenyRequestDesktopDialogComponent {}
