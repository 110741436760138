<div *ngIf="isDownloading">
    <mat-progress-spinner [value]="downloadProgress"></mat-progress-spinner>
    {{ loadedBytes | size }} / {{ totalBytes | size }}
</div>

<ng-container *ngIf="videoSrc">
    <video [ngClass]="cssClassName" controls controlsList="nodownload" preload="none">
        <source [src]="videoSrc | safeUrl" />
        Your browser does not support the video tag.
    </video>
</ng-container>
