import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { CanLoad, Route, Router, UrlSegment } from '@angular/router';

import { EMPTY, map, mergeMap, Observable, of, take, withLatestFrom } from 'rxjs';

import { AuthState } from '@app/data/state';
import { Store } from '@ngxs/store';
import { CookieService } from 'ngx-cookie-service';
import { COOKIE_LOGIN_URL } from '@app/shared/constants';
import { AuthActions } from '@app/data/actions';

@Injectable()
export class IsAuthenticatedGuard implements CanLoad {
    constructor(
        private store: Store,
        private cookieService: CookieService,
        private router: Router,
        private location: Location,
    ) {}

    canLoad(route: Route, segments: UrlSegment[]): Observable<any> {
        // return this.store.select(AuthState.isAuthenticated()).pipe(
        return this.store.select(AuthState.isReady()).pipe(
            withLatestFrom(this.store.select(AuthState.isAuthenticated()), this.store.select(AuthState.getReturnUrl())),
            take(1),
            mergeMap(([, isAuthenticated, returnUrl]) => {
                const loginUrl = this.cookieService.get(COOKIE_LOGIN_URL) || null;

                if (location.search.includes('logout')) {
                    return this.store.dispatch(new AuthActions.Logout(`/login/${loginUrl}`)).pipe(
                        map(() => this.router.parseUrl(`/login/${loginUrl}`)),
                    );
                }

                if (loginUrl && !isAuthenticated) {
                    if (!returnUrl && this.location.path()) {
                        this.store.dispatch(new AuthActions.UpdateReturnUrl(this.location.path()));
                    }

                    this.router.navigate(['/login', loginUrl]);
                }

                return of(isAuthenticated);
            }),
        );
    }
}
