import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';

import { CurrentUser, TokenResult } from '@app/data/models';
import { DeviceDetectorService } from 'ngx-device-detector';

import { Environment } from '../../models';
import { APP_ENVIRONMENT, APP_INITIAL_POINTER_EVENT, VIDEOJS_DEFAULT_CONFIGURATION } from '../../tokens';

@Component({
    selector: 'shared-tiptap-media-asset',
    templateUrl: './tiptap-media-asset.component.html',
    styleUrls: ['./tiptap-media-asset.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TiptapMediaAssetComponent implements OnChanges {
    configuration: any = null;
    controlBarConfiguration = {
        children: {
            playToggle: {},
            progressControl: {},
            volumePanel: {},
            remainingTimeDisplay: {},
            fullscreenToggle: {},
            playbackRateMenuButton: {},
        },
    };

    get isIos() {
        const os = (this.deviceService.getDeviceInfo().os || '').toLocaleLowerCase();
        const mobileOs = ['ios', 'iphone', 'ipad', 'ipod'];

        return this.deviceService.isMobile() && mobileOs.includes(os);
    }

    get isSafari() {
        const browser = (this.deviceService.getDeviceInfo().browser || '').toLocaleLowerCase();
        return browser === 'safari';
    }

    get mediaAssetSrc() {
        if (!this.mediaAssetId) {
            return null;
        }

        return `${this.env.cdnUrl}/${this.env.privateContainerName}/media-assets/${this.mediaAssetId}/playlist.m3u8`;
    }

    @Input() mediaAssetId: string | null = '';

    @Input() watermark: string | null = '';
    @Input() token: TokenResult | null = null;
    @Input() isLoading: boolean | null;
    @Input() currentUser: CurrentUser | null;
    @Input() error: any;

    @Output() readonly playbackError: EventEmitter<any> = new EventEmitter();

    constructor(
        private deviceService: DeviceDetectorService,
        @Inject(APP_ENVIRONMENT) private env: Environment,
        @Inject(VIDEOJS_DEFAULT_CONFIGURATION) private defaultVideoJsConfig: any,
        @Inject(APP_INITIAL_POINTER_EVENT) private hasPointerEventHappened: () => boolean,
    ) {}

    buildVideoConfiguration() {
        if (!this.mediaAssetId) return;

        let cdnUri = '';

        if (this.isIos || this.isSafari) {
            cdnUri = `${this.env.appUrl}/cdn`;
        } else {
            cdnUri = `https://${new URL(this.env.cdnUrl || '').hostname}`;
        }

        const mediaAssetSrc = `${cdnUri}/${this.env.privateContainerName}/media-assets/${this.mediaAssetId}/playlist.m3u8`;

        const config = {
            autoplay: false,
            controls: true,
            preload: 'auto',
            enableDocumentPictureInPicture: false,
            children: {
                controlBar: {
                    children: ['playToggle', 'progressControl', 'volumePanel', 'remainingTimeDisplay', 'theatreRoomToggle', 'fullscreenToggle'],
                },
                videoWatermark: { text: this.watermark },
                bigPlayButton: {},
                loadingSpinner: {},
                tapToPlay: { hasInteracted: () => this.hasPointerEventHappened() },
            },
            playbackRates: [0.5, 0.75, 1, 1.5, 2],
            playlist: [
                {
                    sources: [
                        {
                            src: mediaAssetSrc,
                            type: 'application/vnd.apple.mpegurl',
                        },
                    ],
                },
            ],
        };

        this.configuration = config;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['mediaAssetId'] || changes['watermark']) {
            this.buildVideoConfiguration();
        }
    }
}
