import { Component } from '@angular/core';

import { distinctUntilChanged, tap } from 'rxjs';

import { AuthState, CoreState } from '@app/data/state';
import { Store } from '@ngxs/store';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    title = 'admin-view';

    isLoading$ = this.store.select(CoreState.isLoading());
    isConnected$ = this.store.select(CoreState.isConnected()).pipe(distinctUntilChanged());
    isAuthenticated$ = this.store.select(AuthState.isAuthenticated());

    constructor(private store: Store) {}
}
