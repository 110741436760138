import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@app/shared';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { NgxTiptapModule } from 'ngx-tiptap';

import {
    BreadcrumbComponent,
    CalendlyEventComponent,
    ChecklistComponent,
    CountDownComponent,
    ErrorDialogComponent,
    ErrorDisplayComponent,
    FormSubmissionsComponent,
    FormPreviewComponent,
    FormSectionBookingComponent,
    FormSectionChartComponent,
    FormSectionComponent,
    FormSectionDownloadComponent,
    FormSectionEventComponent,
    FormSectionMediaComponent,
    FormSectionQuestionComponent,
    FormSectionSummaryComponent,
    FormSectionTextComponent,
    FormSubmissionListComponent,
    GlobalLoadingComponent,
    LinkNoticeComponent,
    LoadingButtonComponent,
    LoadingComponent,
    LoginFormComponent,
    LogViewerComponent,
    NodeTreeComponent,
    SearchComponent,
    SearchResultItemDirective,
    SearchResultsComponent,
    SearchResultsDirective,
    SessionGsSummaryComponent,
    SessionHistoryComponent,
    SessionIntakeSubmissionComponent,
    SessionListComponent,
    ViewFormSubmissionComponent,
    ViewPaymentComponent,
    FormChartTooltipComponent,
    PrintFormSubmissionsComponent,
    ViewFormSubmissionsComponent,
    LinkExpirationComponent,
    FormSectionWebinarComponent,
    FormSectionMeetingComponent,
    RegistrantDetailsComponent,
    FormSubmissionsExportComponent,
    LoadingSnackbarComponent,
    RichTextViewComponent,
    TiptapZoomLinkComponent,
} from './components';
import { RequestTimeDialogComponent, SubmissionCompleteDialogComponent } from './dialogs';
import { MatDividerModule } from '@angular/material/divider';
import { TiptapAudioComponent } from './components';

const DECLARATIONS = [
    SearchResultItemDirective,
    SearchResultsComponent,
    SearchResultsDirective,
    ErrorDisplayComponent,
    SearchComponent,
    LoadingComponent,
    GlobalLoadingComponent,
    LoginFormComponent,
    BreadcrumbComponent,
    NodeTreeComponent,
    LinkNoticeComponent,
    CountDownComponent,
    SessionHistoryComponent,
    SessionListComponent,
    ViewFormSubmissionComponent,
    LogViewerComponent,

    FormSectionTextComponent,
    FormSectionQuestionComponent,
    FormSectionMediaComponent,
    FormSectionComponent,
    FormSectionDownloadComponent,
    FormSectionEventComponent,
    FormSectionSummaryComponent,
    FormSectionBookingComponent,
    FormPreviewComponent,

    SessionGsSummaryComponent,
    SessionIntakeSubmissionComponent,

    ChecklistComponent,
    ViewPaymentComponent,
    LoadingButtonComponent,
    CalendlyEventComponent,
    FormSectionTextComponent,
    ErrorDialogComponent,
    SubmissionCompleteDialogComponent,

    FormSubmissionListComponent,
    FormSectionChartComponent,
    FormSubmissionsComponent,
    FormChartTooltipComponent,

    PrintFormSubmissionsComponent,
    ViewFormSubmissionsComponent,
    LinkExpirationComponent,
    FormSectionWebinarComponent,
    FormSectionMeetingComponent,
    RegistrantDetailsComponent,

    FormSubmissionsExportComponent,
    LoadingSnackbarComponent,
    RequestTimeDialogComponent,

    TiptapAudioComponent,
    RichTextViewComponent,

    TiptapZoomLinkComponent,
];

const MATERIAL = [
    MatToolbarModule,
    MatListModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatTreeModule,
    MatBadgeModule,
    MatDatepickerModule,
    MatListModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatDialogModule,
    MatRippleModule,
    MatDividerModule,
    MatTableModule,

    OverlayModule,
];

@NgModule({
    declarations: [...DECLARATIONS],
    imports: [
        ...MATERIAL,

        ContentLoaderModule,
        NgxTiptapModule,
        FlexLayoutModule,

        SharedModule,
        ReactiveFormsModule,
        RouterModule,
        CommonModule,
    ],
    exports: [...DECLARATIONS],
})
export class UiModule {}
