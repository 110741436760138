import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './request-time-dialog.component.html',
    styleUrls: ['./request-time-dialog.component.scss'],
})
export class RequestTimeDialogComponent {
    get message(): string {
        return (
            this.data?.message ||
            'Your session has expired for this link. Would you like to request more time? You will need to re-submit any questions for this link.'
        );
    }

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
