<div *ngIf="joinUrl" [ngStyle]="getStyling()" class="player">
    <div class="link-wrapper" matRipple *ngIf="showJoinUrl">
        <span class="link">
            <a target="_blank" rel="nofollow" [href]="joinUrl">{{ joinUrl }}</a>
        </span>
    </div>
    <span matRipple class="password" *ngIf="password && showJoinUrl" (click)="onCopyPasswordToClipboard()"
        >Passcode: {{ password }}</span
    >

    <button *ngIf="showJoinButton" mat-raised-button color="primary" (click)="onJoinMeeting()">
        <mat-spinner *ngIf="isJoiningMeeting" class="spinner" diameter="20"></mat-spinner>Join Now<mat-icon
            >video_call</mat-icon
        >
    </button>
    <button class="nowrap" *ngIf="showCopyLinkButton" mat-raised-button (click)="onCopyLinkToClipboard()">
        <mat-icon>content_copy</mat-icon>
        Copy Link
    </button>
    <button class="nowrap" *ngIf="password && showCopyPasswordButton" mat-raised-button (click)="onCopyPasswordToClipboard()">
        <mat-icon>content_copy</mat-icon>
        Copy Passcode
    </button>
</div>
