import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

import { FormPage, FormSection, LinkSessionSummary, Submission } from '@app/data/models';
import { FormSectionType } from '@app/shared/enums';

@Component({
    selector: 'ui-view-form-submissions',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './view-form-submissions.component.html',
    styleUrls: ['./view-form-submissions.component.scss'],
})
export class ViewFormSubmissionsComponent  {
    sections: FormSection[] = [];
    FormSectionType: typeof FormSectionType = FormSectionType;

    @Input() summaries: LinkSessionSummary[] | null = null;
    @Input() submissions: Submission[] | null = null;
    @Input() pages: FormPage[] | null = [];

    constructor(public cd: ChangeDetectorRef) {}

    getFormSubmission(summary: LinkSessionSummary): Submission {
        return this.submissions?.find(
            s => s.visitorId === summary.visitorId && s.sessionId === summary.selectedSessionId,
        ) as Submission;
    }
}
