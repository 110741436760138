export const CODE_LENGTH = 6;
export const PASSWORD_MINIMUM_LENGTH = 8;
export const URL_MINIMUM_LENGTH = 4;

export const NO_CONNECTION = 0;
export const SERVER_ERROR = 500;
export const NOT_FOUND = 404;
export const UNAUTHORIZED = 401;
export const FORBIDDEN = 403;

export const COOKIE_LOGIN_URL = 'l';
export const DATETIME_FORMAT = 'MMM DD YYYY @ hh:mmA';
export const SHARED_DATE_REGEX = '^(\\d{4}-\\d\\d-\\d\\d)(T| )(\\d\\d:\\d\\d)([+-]\\d\\d\\d\\d|Z)?$';
export const SHARED_URL_REGEX = '^[a-zA-Z0-9&\\-_.@]{1,64}$';
export const SHARED_URL_ALLOWED_CHARACTERS_REGEX = '[a-zA-Z0-9&\\-_.@]';
export const PHONE_NUMBER_REGEX = '^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$';

export const ORGANIZATION_URL_REGEX = '^[a-zA-Z0-9&\\-+!$[\\]_.@(,*)=]{1,31}$';
export const ORGANIZATION_ID_REGEX = '^[a-z][a-z0-9]{2,15}$';

export const SECONDS_1 = 1000;
export const SECONDS_5 = 5000;
export const SECONDS_10 = 10000;
export const SECONDS_15 = 15000;
export const SECONDS_30 = 30000;
export const SECONDS_60 = 60000;
export const SECONDS_90 = 90000;
export const SECONDS_120 = 120000;
export const SECONDS_180 = 180000;

export const HOURS_1 = 3600000;

export const MEGABYTE_1 = 1048576;

export const JWT_NAME = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name';
