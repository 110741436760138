import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

import { LinkModel, LinkSessionSummary } from '@app/data/models';

@Component({
    selector: 'ui-form-submissions-export',
    templateUrl: './form-submissions-export.component.html',
    styleUrls: ['./form-submissions-export.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSubmissionsExportComponent implements OnChanges {
    dataSource: LinkSessionSummary[] = [];
    displayedColumns = [
        'name',
        'joinTime',
        'leaveTime',
        'durationInSeconds',
    ];

    @Input() link: LinkModel | null;
    @Input() category: string | null;
    @Input() sessions: LinkSessionSummary[] | null;

    ngOnChanges(): void {
        this.dataSource = (this.sessions ?? []).sort((a, b) => a.name.localeCompare(b.name));
    }
}
