import { NgModule } from '@angular/core';
import { AnyPipe } from './any.pipe';
import { ArrayPipe } from './array.pipe';
import { ElapsedTimePipe } from './elapsed-time.pipe';
import { ExpiresPipe } from './expires.pipe';
import { FromEnumPipe } from './from-enum.pipe';
import { HighlightPipe } from './highlight.pipe';
import { NodeColourPipe } from './node-colour.pipe';
import { NodeTypeIconPipe } from './node-type-icon.pipe';
import { NodeTypePipe } from './node-type.pipe';
import { PadPipe } from './pad.pipe';
import { PaymentStatusPipe } from './payment-status.pipe';

import { RawValuePipe } from './raw-value.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { SizePipe } from './size.pipe';
import { SubmissionDatePipe } from './submission-data.pipe';
import { TimerUnitPipe } from './timer-unit.pipe';
import { ToLowerCasePipe } from './to-lowercase.pipe';
import { FormatDatePipe } from './format-date.pipe';
import { FormatLinkUrlPipe } from './format-link-url.pipe';
import { HasContentPipe } from './has-content.pipe';
import { FormatLinkExpiryPipe } from './format-link-expiry.pipe';
import { IncludesPipe } from './includes.pipe';
import { IndicatorIconPipe } from './indicator-icon.pipe';
import { IsFutureDatePipe } from './is-future-date.pipe';
import { DayOfWeekPipe } from './day-of-week.pipe';
import { FileSizePipe } from './file-size.pipe';
import { CdnUrlPipe } from './cdn-url.pipe';

const PIPES = [
    AnyPipe,
    ElapsedTimePipe,
    FromEnumPipe,
    HighlightPipe,
    NodeColourPipe,
    NodeTypeIconPipe,
    NodeTypePipe,
    PadPipe,
    RawValuePipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    SizePipe,
    ToLowerCasePipe,
    ExpiresPipe,
    TimerUnitPipe,
    ArrayPipe,
    SubmissionDatePipe,
    PaymentStatusPipe,
    FormatDatePipe,
    FormatLinkUrlPipe,
    HasContentPipe,
    FormatLinkExpiryPipe,
    IncludesPipe,
    IndicatorIconPipe,
    IsFutureDatePipe,
    DayOfWeekPipe,
    FileSizePipe,
    CdnUrlPipe,
];

@NgModule({
    declarations: [...PIPES],
    exports: [...PIPES],
})
export class PipesModule {}
