import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'fromEnum',
})
export class FromEnumPipe implements PipeTransform {
    // takes an enum, and returns an array with the enum values
    transform(target: any, mode: 'default' | 'reverse' | 'all' = 'default'): string[] {
        switch (mode) {
            case 'default':
                return Object.keys(target)
                    .filter(s => {
                        return /^[^\d]{1,}/.test(s);
                    })
                    .map(key => target[key]);
            case 'reverse':
                return Object.keys(target)
                    .filter(s => {
                        return /^\d{1,}/.test(s);
                    })
                    .map(key => target[key]);
        }

        return Object.keys(target).map(key => target[key]);
    }
}
