import { ErrorCodes } from '../enums';
import { getErrorCodeMessage, isNumber } from '../util';

export class ServerResult<T> {
    data!: T;
    status!: number;
    message!: string;
    meta: any;

    constructor(source: any) {
        Object.assign(this, source);
        // console.log('serverresult', source);
    }

    get errorCode(): ErrorCodes {
        return this.hasErrorCode ? this.meta.errorCode : 0;
    }

    get hasErrorCode(): boolean {
        return this.meta && isNumber(this.meta.errorCode);
    }

    get validationResults(): string[] {
        return this.meta && this.meta.Results ? this.meta.Results : [];
    }

    get errorCodeMessage(): string {
        return getErrorCodeMessage(this.errorCode, this.meta);
    }
}

export function isServerResult(model: any): model is ServerResult<any> {
    return model instanceof ServerResult;
}
