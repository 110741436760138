import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Environment } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';

@Injectable()
export class ParamInterceptor implements HttpInterceptor {
    constructor(@Inject(APP_ENVIRONMENT) private env: Environment) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const authReq = this.addToken(req);
        return next.handle(authReq);
    }

    addToken(request: HttpRequest<any>): HttpRequest<any> {
        const paramToken = location.search.includes('token=')
            ? (
                  location.search
                      .substring(1)
                      .split('&')
                      .find(kv => kv.startsWith('token=')) || ''
              ).split('=')[1]
            : null;
        const isServerUrl = request.url.indexOf(this.env.serverUrl) >= 0;
        const appendTokenParam = isServerUrl && paramToken && (!request.params || !request.params.has('token'));

        if (appendTokenParam) {
            return request.clone({
                params: new HttpParams({
                    fromObject: {
                        token: paramToken || '',
                    },
                }),
            });
        }

        return request;
    }
}
