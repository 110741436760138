import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from '@angular/core';

import { BaseNode, GsVideoSummary } from '@app/data/models';
import { NodeType } from '@app/shared/enums';

@Component({
    selector: 'ui-session-gs-summary',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './session-gs-summary.component.html',
    styleUrls: ['./session-gs-summary.component.scss'],
})
export class SessionGsSummaryComponent {
    @ViewChild('nodeTemplate', { static: true }) nodeTemplateRef: TemplateRef<any>;

    @Input() summary: GsVideoSummary | null = null;
    @Input() nodes: BaseNode[] | null = null;

    isDisabled(summary: GsVideoSummary, node: BaseNode) {
        if (node.type === NodeType.Audio || node.type === NodeType.Video) {
            return (
                summary !== null &&
                !summary.completedNodeIds.includes(node.rowKey) &&
                !summary.activeNodeIds.includes(node.rowKey)
            );
        }

        return false;
    }

    isComplete(summary: GsVideoSummary, node: BaseNode) {
        if (node.type === NodeType.Audio || node.type === NodeType.Video) {
            return summary !== null && summary.completedNodeIds.includes(node.rowKey);
        }

        return false;
    }
}
