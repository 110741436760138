import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Environment } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';
import { Store } from '@ngxs/store';

import { AuthState } from '../state';

@Injectable()
export class FpjsInterceptor implements HttpInterceptor {
    constructor(@Inject(APP_ENVIRONMENT) private env: Environment, private store: Store) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const visitorId = this.store.selectSnapshot(AuthState.getVisitorId()) || '';
        const thumbprint = this.store.selectSnapshot(AuthState.getThumbprint()) || '';
        const requestId = this.store.selectSnapshot(AuthState.getRequestId()) || '';
        const authReq = this.addHeaders(req, visitorId, requestId, thumbprint);

        return next.handle(authReq);
    }

    addHeaders(request: HttpRequest<any>, visitorId: string, requestId: string, thumbprint: string): HttpRequest<any> {
        const headers: { [key: string]: string } = {};
        if (request.url.indexOf(this.env.serverUrl) === -1 && request.url.indexOf(this.env.proxyServerUrl) === -1) {
            // request is not to our server, so fast return
            return request;
        }

        if (!request.headers.has('X-request-id') && requestId) {
            headers['X-request-id'] = requestId;
        }

        if (!request.headers.has('X-visitor-id') && visitorId) {
            headers['X-visitor-id'] = visitorId;
        }

        if (!request.headers.has('X-thumbprint') && thumbprint) {
            headers['X-thumbprint'] = thumbprint;
        }

        return request.clone({
            setHeaders: headers,
        });
    }
}
