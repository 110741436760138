import { Pipe, PipeTransform } from '@angular/core';

import { DayOfWeek } from '../enums';

@Pipe({
    name: 'dayOfWeek',
})
export class DayOfWeekPipe implements PipeTransform {
    transform(value: DayOfWeek | null): string {

        switch (value) {
            case DayOfWeek.Monday:
                return 'Monday';
            case DayOfWeek.Tuesday:
                return 'Tuesday';
            case DayOfWeek.Wednesday:
                return 'Wednesday';
            case DayOfWeek.Thursday:
                return 'Thursday';
            case DayOfWeek.Friday:
                return 'Friday';
            case DayOfWeek.Saturday:
                return 'Saturday';
            case DayOfWeek.Sunday:
                return 'Sunday';
        }

        return '';
    }
}
