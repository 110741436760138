<span
    *ngIf="!isEnumerable && hasError; else list"
    class="message"
    [ngClass]="{ 'is-critical': error?.isCritical, icons: showIcons }"
>
    {{ message }}
</span>
<ng-template #list>
    <div
        class="message"
        [ngClass]="{ 'is-critical': error?.isCritical, icons: showIcons }"
        *ngFor="let e of getMessage(error)"
    >
        {{ e }}
    </div>
</ng-template>
<ul *ngIf="isValidationError">
    <li *ngFor="let error of validationResults">
        {{ error }}
    </li>
</ul>
