import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TimeoutError } from 'rxjs';

import { ErrorCodes } from '../../enums';
import { ErrorModel, isErrorModel } from '../../models';
import {
    getHttpErrorMessage,
    getMediaErrorMessage,
    isArray,
    isError,
    isHttpError,
    isMediaError,
    isObject,
    isString,
} from '../../util';

@Component({
    selector: 'shared-error-display',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: `./error-display.component.html`,
    styleUrls: ['./error-display.component.scss'],
})
export class ErrorDisplayComponent {
    @Input() error: ErrorModel<any> | any = null;
    @Input() messages: { [key: string]: string } | null = null;
    @Input() showIcons: boolean | null = true;

    get message(): string | null {
        return this.getMessage(this.error).shift() || null;
    }

    get validationResults() {
        return isErrorModel(this.error) ? this.error.validationResults : [];
    }

    get isValidationError() {
        return isErrorModel(this.error) && this.error.errorCode === ErrorCodes.ValidationError;
    }

    get hasError(): boolean {
        const message = this.message || '';
        return message !== '';
    }

    get isEnumerable(): boolean {
        return isArray(this.error);
    }

    getMessage(value: unknown): string[] {
        let messages = [];
        if (value === null || value === undefined) {
            return [];
        }

        if (isArray(value)) {
            messages = value.reduce((acc: string[], curr: any) => {
                acc = acc.concat(this.getMessage(curr));
                return acc;
            }, []) as string[];
        } else if (isErrorModel(value)) {
            messages = [this.getMessageMapping(ErrorCodes[value.errorCode]) || value.message];
        } else if (isHttpError(value)) {
            messages = [getHttpErrorMessage(value)];
        } else if (isError(value)) {
            messages = [value.message];
        } else if (isMediaError(value)) {
            messages = [getMediaErrorMessage(value)];
        } else if (isString(value)) {
            messages = [value || ''];
        } else if (isObject(value)) {
            switch (true) {
                case value instanceof TimeoutError:
                    messages = ['Timeout occured while uploading content'];
            }
            messages = this.getErrorObjectMessage(value as any); // recursive??
        } else {
            messages = [JSON.stringify(value || '')];
        }

        return messages.filter(s => s && s.length > 0);
    }

    getMessageMapping(key: string): string | null {
        return this.messages && this.messages[key];
    }

    getErrorObjectMessage(value: { [key: string]: { [key: string]: boolean } }): string[] {
        const keys = Object.keys(value || {});
        let messages: string[] = [];
        for (const key of keys) {
            switch (key) {
                case 'required':
                    messages = messages.concat([this.getMessageMapping(key) || 'You must enter a value.']);
                    break;
                case 'email':
                    messages = messages.concat([this.getMessageMapping(key) || 'Invalid email.']);
                    break;
                case 'pattern':
                    messages = messages.concat([this.getMessageMapping(key) || 'Invalid value.']);
                    break;
                case 'minlength':
                    messages = messages.concat([
                        this.getMessageMapping(key) || `Minimum length is ${value[key]['requiredLength']}.`,
                    ]);
                    break;
                case 'maxlength':
                    messages = messages.concat([
                        this.getMessageMapping(key) || `Maximum length is ${value[key]['requiredLength']}.`,
                    ]);
                    break;
                case 'min':
                    messages = messages.concat([this.getMessageMapping(key) || `Minimum value is ${value[key]['min']}.`]);
                    break;
                case 'max':
                    messages = messages.concat([this.getMessageMapping(key) || `Maximum value is ${value[key]['max']}.`]);
                    break;
                default:
                    messages = messages.concat(this.getMessage(value[key]));
                    break;
            }
        }

        return messages;
    }
}
