<ui-node-tree [nodes]="nodes" [nodeTemplate]="nodeTemplate" [nodeTemplateContext]="summary"></ui-node-tree>

<ng-template #nodeTemplate let-node let-summary="data">
    <div fxLayout="row nowrap" fxLayoutAlign="start center" [ngClass]="{ disabled: isDisabled(summary, node.data) }">
        <div class="indent">
            <mat-icon *ngIf="isComplete(summary, node.data)" class="complete">check</mat-icon>
        </div>

        <mat-icon [ngClass]="node.data | nodeColour">{{ node.data | nodeTypeIcon }}</mat-icon>
        <a class="node-name">
            <span>{{ node.name }}</span>
        </a>
        <time *ngIf="node.data.duration">&nbsp;({{ node.data.duration }})</time>
    </div>
</ng-template>