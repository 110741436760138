import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';

import { debounceTime, filter, Subject, takeUntil, tap } from 'rxjs';

import { FormQuestionType } from '@app/shared/enums';
import { FormSectionOption } from '@app/data/models';
import { formatDatetime } from '@app/shared/util';

export interface QuestionSectionModel {
    id: string;

    title: string;
    description: string;
    hint: string;
    type: FormQuestionType | null;
    isRequired: boolean;
    minLabel: string | null;
    maxLabel: string | null;
    options: { [key: string]: string | number | boolean }[];
    matrix: { rows: FormSectionOption[]; columns: FormSectionOption[] };
    showOther: boolean;
    futureDatesOnly: boolean;
    value?: string | number | boolean | Date | string[] | string[][] | number[] | null;
}

const DEBOUNCE_IN_MS = 300;

@Component({
    selector: 'ui-form-section-question',
    templateUrl: './form-section-question.component.html',
    styleUrls: ['./form-section-question.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSectionQuestionComponent implements OnChanges, OnDestroy {
    isAlive$: Subject<void> = new Subject();
    isUpdating = true;

    FormQuestionType: typeof FormQuestionType = FormQuestionType;

    form = this.fb.group<{ [key: string]: AbstractControl }>({});

    onChange$ = this.form.valueChanges
        .pipe(
            takeUntil(this.isAlive$),
            filter(() => !this.isUpdating),
            tap(() => this.pendingChanges.emit()),
            debounceTime(DEBOUNCE_IN_MS),
            tap(value => {
                switch (this.model?.type) {
                    case FormQuestionType.MultipleChoice:
                        this.valueChanged.emit({
                            ...value,
                            value: value['value'] || value['other'],
                        });
                        break;
                    case FormQuestionType.Checkbox:
                        this.valueChanged.emit({
                            id: value['id'],
                            value: (this.showOtherOption && this.isOtherValueChecked ? [value['other']] : []).concat(
                                value['options']
                                    .filter((o: { checked: boolean; id: string }) => o.checked)
                                    .map((o: { checked: boolean; id: string }) => o.id),
                            ),
                        });
                        break;
                    case FormQuestionType.MatrixCheckbox:
                        {
                            const matrixValue: string[][] = [];

                            this.model.matrix.rows.forEach((row, ix) => {
                                const rowValue: string[] = [];
                                this.model?.matrix.columns.forEach((col, jx) => {
                                    if (value['value'][ix][jx].checked === true) {
                                        rowValue.push(value['value'][ix][jx].id);
                                    }
                                });
                                matrixValue.push(rowValue);
                            });

                            this.valueChanged.emit({
                                id: value['id'],
                                value: matrixValue,
                            });
                        }
                        break;
                    default:
                        this.valueChanged.emit(value);
                        break;
                }
            }),
        )
        .subscribe();

    get showTitle() {
        return this.model && this.model.title;
    }

    get title() {
        return this.model?.title;
    }

    get options() {
        return this.model?.options;
    }

    get type() {
        return this.model?.type;
    }

    get showOtherOption(): boolean {
        return this.model?.showOther || false;
    }

    get min() {
        const options = this.options as { id: string; text: string }[];
        if (options && options.length > 0) {
            return options[0].text;
        }

        return '';
    }

    get max() {
        const options = this.options as { id: string; text: string }[];
        if (options && options.length > 1) {
            return options[options.length - 1].text;
        }
        return '';
    }

    get minDateValue() {
        if (this.model?.futureDatesOnly) {
            return new Date();
        }

        return null;
    }

    get minLabel() {
        return this.model?.minLabel;
    }

    get maxLabel() {
        return this.model?.maxLabel;
    }

    get optionsCtrl(): FormArray {
        if (this.type === FormQuestionType.Checkbox) {
            return this.form.get('options') as FormArray;
        }

        return this.fb.array([]);
    }

    get valueCtrl(): FormArray {
        if (this.type === FormQuestionType.Matrix || this.type === FormQuestionType.MatrixCheckbox) {
            return this.form.get('value') as FormArray;
        }

        return this.fb.array([]);
    }

    get rowsCtrl(): FormArray {
        if (this.type === FormQuestionType.Matrix || this.type === FormQuestionType.MatrixCheckbox) {
            return this.form.controls['value'] as FormArray;
        }

        return this.fb.array([]);
    }

    get otherValue() {
        const value = (this.model?.value || []) as string[];
        const otherValue = value.find(p => !this.options?.some(o => o['id'] === p)) || '';

        return otherValue;
    }

    isOtherValueChecked = false;

    @Input() print: boolean | null = false;
    @Input() readonly: boolean | null = false;
    @Input() model: QuestionSectionModel | null = null;

    @Output() readonly valueChanged: EventEmitter<any> = new EventEmitter();
    @Output() readonly pendingChanges: EventEmitter<void> = new EventEmitter();

    constructor(private fb: FormBuilder) {}

    onCheckChanged(ctrl?: AbstractControl, ix?: number) {
        this.optionsCtrl.updateValueAndValidity();
        this.valueCtrl.updateValueAndValidity();
    }

    clearControls() {
        const keys = Object.keys(this.form.controls);
        keys.forEach(key => {
            this.form.removeControl(key);
        });
    }

    isRequiredValidator(): ValidatorFn {
        return (ctrl: AbstractControl) => {
            if (this.model?.type === FormQuestionType.Matrix) {
                const value = (this.form.get('value')?.value || []) as string[];
                if ((this.model?.isRequired && value.length !== this.model?.matrix.rows.length) || value.some(v => !v)) {
                    return { required: true };
                }
            } else if (this.model?.type === FormQuestionType.MatrixCheckbox) {
                const value = (this.form.get('value')?.value || []) as {
                    id: string;
                    checked: boolean;
                }[][];
                if (
                    (this.model?.isRequired && value.length !== this.model?.matrix.rows.length) ||
                    value.some(v => v.every(r => !r.checked))
                ) {
                    return { required: true };
                }
            } else {
                const value = (ctrl.value?.toString() || '').trim();
                if (this.model?.isRequired && value === '') {
                    return { required: true };
                }
            }

            return null;
        };
    }

    isRequiredValidatorWithOther(): ValidatorFn {
        return (ctrl: AbstractControl) => {
            const valueCtrl = this.form.get('value');
            const otherCtrl = this.form.get('other');
            const value = (valueCtrl?.value || '').trim();
            const other = (otherCtrl?.value || '').trim();

            if (this.model?.isRequired) {
                if (value) {
                    otherCtrl?.setErrors(null);
                } else if (other) {
                    valueCtrl?.setErrors(null);
                }

                if (value === '' && other === '') {
                    return { required: true };
                }
            }

            return null;
        };
    }

    isRequiredValidatorWithOtherCheckbox(): ValidatorFn {
        return (ctrl: AbstractControl) => {
            const otherCtrl = this.form.get('other');
            const other = (otherCtrl?.value || '').trim();

            if (this.isOtherValueChecked && other === '') {
                return { required: true };
            }

            return null;
        };
    }

    removeControl(name: string) {
        if (this.form.get(name)) {
            this.form.removeControl(name);
        }
    }

    addControl(name: string, validators: ValidatorFn[] = []): AbstractControl {
        if (!this.form.get(name)) {
            this.form.addControl(name, this.fb.control('', [...validators]));
        }

        return this.form.get(name) as AbstractControl;
    }

    onOtherValueChange(event: MatCheckboxChange) {
        this.isOtherValueChecked = event.checked;
        const otherCtrl = this.form.get<string>('other');
        otherCtrl?.enable();

        if (!event.checked) {
            otherCtrl?.setValue('');
            otherCtrl?.disable();
        }
    }

    updateControlValidation(name: string) {
        const ctrl = this.form.get(name);
        ctrl?.markAsTouched();
        ctrl?.updateValueAndValidity();
    }

    updateForm() {
        if (!this.model) {
            return;
        }

        this.isUpdating = true;

        this.addControl('id', []).setValue(this.model.id);

        switch (this.type) {
            case FormQuestionType.ShortAnswer:
                this.removeControl('other');
                this.removeControl('options');
                this.addControl('value', [this.isRequiredValidator()]).setValue(this.model.value);
                this.updateControlValidation('value');

                break;
            case FormQuestionType.LongAnswer:
                this.removeControl('other');
                this.removeControl('options');
                this.addControl('value', [this.isRequiredValidator()]).setValue(this.model.value);
                this.updateControlValidation('value');
                break;
            case FormQuestionType.MultipleChoice:
                {
                    this.removeControl('options');
                    const value = this.model.value;
                    if (this.model.options.some(p => p['id'] === value)) {
                        this.addControl('other', [this.isRequiredValidatorWithOther(), Validators.minLength(15)]);
                        this.addControl('value', [this.isRequiredValidatorWithOther()]).setValue(value);
                        this.updateControlValidation('other');
                        this.updateControlValidation('value');
                    } else {
                        this.addControl('other', [this.isRequiredValidatorWithOther(), Validators.minLength(15)]).setValue(
                            value,
                        );
                        this.addControl('value', [this.isRequiredValidatorWithOther()]).setValue(null);
                        this.updateControlValidation('other');
                        this.updateControlValidation('value');
                    }
                }
                break;
            case FormQuestionType.Dropdown:
                this.removeControl('other');
                this.removeControl('options');
                this.addControl('value', [this.isRequiredValidator()]).setValue(this.model.value);
                this.updateControlValidation('value');
                break;
            case FormQuestionType.Scale:
                this.removeControl('other');
                this.removeControl('options');
                this.addControl('value', [this.isRequiredValidator()]).setValue(this.model.value);
                this.updateControlValidation('value');
                break;
            case FormQuestionType.Date:
                {
                    this.removeControl('other');
                    this.removeControl('options');
                    this.addControl('value', [this.isRequiredValidator()]).setValue(this.model.value);
                    this.updateControlValidation('value');
                }
                break;
            case FormQuestionType.Matrix:
                {
                    this.removeControl('other');
                    this.removeControl('value');
                    this.removeControl('options');

                    const array = this.fb.array([], [this.isRequiredValidator()]);
                    const value = (this.model.value as string[]) || new Array(this.model.matrix.rows.length).fill('');
                    this.model.matrix.rows.forEach((row, ix) => {
                        array.push(this.fb.control(value[ix]));
                    });

                    this.form.addControl('value', array);
                }
                break;
            case FormQuestionType.MatrixCheckbox:
                {
                    this.removeControl('other');
                    this.removeControl('value');
                    this.removeControl('options');

                    const array = this.fb.array<AbstractControl>([], [this.isRequiredValidator()]);
                    const value = (this.model.value as string[][]) || new Array(this.model?.matrix.rows.length).fill([]);
                    this.model.matrix.rows.forEach((row, ix) => {
                        const rowArray = this.fb.array<AbstractControl>([], []);
                        this.model?.matrix.columns.forEach((col, colIx) => {
                            rowArray.push(
                                this.fb.group({
                                    id: this.model?.matrix.columns[colIx].id,
                                    // text: this.model?.matrix.columns[colIx].text,
                                    checked: [value[ix].some(v => v === this.model?.matrix.columns[colIx].id)],
                                }),
                            );
                        });

                        array.push(rowArray);
                    });

                    this.form.addControl('value', array);
                }
                break;
            case FormQuestionType.Checkbox:
                {
                    this.removeControl('value');

                    const value = (this.model.value || []) as string[];
                    const otherValue = this.otherValue;

                    const otherCtrl = this.addControl('other', [
                        this.isRequiredValidatorWithOtherCheckbox(),
                        Validators.minLength(15),
                    ]);
                    otherCtrl.setValue(otherValue);

                    this.isOtherValueChecked = otherValue !== '';
                    if (!this.isOtherValueChecked) {
                        otherCtrl.disable();
                    }

                    if (!this.optionsCtrl) {
                        this.form.addControl('options', this.fb.array([]));
                    }

                    const options = this.optionsCtrl;
                    options.clear();

                    this.options?.forEach(opt => {
                        options.controls.push(
                            this.fb.group({
                                id: [opt['id']],
                                text: [opt['text']],
                                checked: [value.includes(opt['id'] as string)],
                            }),
                        );
                    });
                }
                break;
        }

        this.isUpdating = false;
    }

    getMatrixCtrl(rowIx: number, colIx: number) {
        const row = this.rowsCtrl.at(rowIx) as FormArray;
        return row.at(colIx) as FormGroup;
    }

    getPrintValue(): string {
        switch (this.type) {
            case FormQuestionType.Date:
                return formatDatetime(this.form.get('value')?.value);
            case FormQuestionType.Dropdown:
                {
                    const value = this.form.get('value')?.value;
                    const option = this.options?.find(opt => opt['id'] === value);

                    return option?.['text'].toString() || '';
                }
        }

        return '';
    }

    isQuestionTypeDifferent(current: QuestionSectionModel, previous: QuestionSectionModel): boolean {
        if (!current || !previous) {
            return false;
        }
        return current.type !== previous.type;
    }

    isOptionsDifferent(current: QuestionSectionModel, previous: QuestionSectionModel): boolean {
        if (!current || !previous) {
            return false;
        }
        if (current.options?.length !== previous.options?.length) {
            return true;
        }
        for (let i = 0; i < current.options.length; i++) {
            if (current.options[i]['id'] !== previous.options[i]['id']) {
                return true;
            }
        }
        return false;
    }

    isMatrixDifferent(current: QuestionSectionModel, previous: QuestionSectionModel): boolean {
        if (!current || !previous) {
            return false;
        }

        if (
            current.matrix?.rows.length !== previous.matrix?.rows.length ||
            current.matrix?.columns.length !== previous.matrix?.columns.length
        ) {
            return true;
        }

        for (let i = 0; i < current.matrix?.rows.length; i++) {
            if (current.matrix.rows[i].id !== previous.matrix.rows[i].id) {
                return true;
            }
        }

        for (let i = 0; i < current.matrix?.columns.length; i++) {
            if (current.matrix.columns[i].id !== previous.matrix.columns[i].id) {
                return true;
            }
        }

        return false;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['model']) {
            if (
                changes['model'].firstChange ||
                this.isQuestionTypeDifferent(changes['model'].currentValue, changes['model'].previousValue) ||
                this.isOptionsDifferent(changes['model'].currentValue, changes['model'].previousValue) ||
                this.isMatrixDifferent(changes['model'].currentValue, changes['model'].previousValue)
            ) {
                this.updateForm();
            }
        }
    }

    ngOnDestroy(): void {
        this.isAlive$.next();
        this.isAlive$.complete();
    }
}
