import { Pipe, PipeTransform } from '@angular/core';
import { hasContent } from '../util';

@Pipe({
    name: 'hasContent',
})
export class HasContentPipe implements PipeTransform {
    transform(value: string|null|undefined, isHtml: boolean = false): boolean {
        return hasContent(value, isHtml);
    }
}
