import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NodeColours } from '@app/shared/enums';

export interface FormSectionModel {
    id: string;

    title: string;
    description: string;
    backgroundColour: NodeColours | null;
    fontColour: NodeColours | null;
}

@Component({
    selector: 'ui-form-section',
    templateUrl: './form-section.component.html',
    styleUrls: ['./form-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSectionComponent {
    get showTitle() {
        return this.model && this.model.title;
    }

    get title() {
        return this.model?.title;
    }

    get description() {
        return this.model?.description || '';
    }

    get bgColour(): string {
        return (this.model?.backgroundColour || '').toString();
    }

    get fontColour(): string {
        return (this.model?.fontColour || '').toString();
    }

    @Input() readonly = false;
    @Input() model: FormSectionModel | null = null;
}
