import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: 'input[focus]',
})
export class FocusDirective implements AfterViewInit {
    @Input('focus')
    focused = false;

    constructor(public element: ElementRef<HTMLInputElement>) {}

    ngAfterViewInit(): void {
        if (this.focused) {
            setTimeout(() => {
                this.element.nativeElement.focus();
            }, 0);
        }
    }
}
