import { VERSION as ngVersion } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApplicationRef, NgModule } from '@angular/core';
import { VERSION as ngMaterialVersion } from '@angular/material/core';
import { MatIconRegistry } from '@angular/material/icon';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { map } from 'rxjs';

import { LoginInterceptor, NoConnectionInterceptor } from '@app/admin/interceptors';
import { DataModule } from '@app/data';
import { ApiVersionInterceptor, CredentialsInterceptor, DateInterceptor, TokenInterceptor } from '@app/data/interceptors';
import { AuthService } from '@app/data/services';
import { SharedModule } from '@app/shared';
import { Environment } from '@app/shared/models';
import { APP_AUTOUPDATE, APP_DOCUMENT, APP_ENVIRONMENT, APP_FPJS_TOKEN, APP_WINDOW } from '@app/shared/tokens';
import { UiModule } from '@app/ui';
import { FingerprintjsProAngularModule } from '@fingerprintjs/fingerprintjs-pro-angular';
import { BarController, BarElement, CategoryScale, Chart, Legend, LinearScale, SubTitle, Title, Tooltip } from 'chart.js';
import { ToastrModule } from 'ngx-toastr';

import { environment } from '../environments/environment';
import { IsAuthenticatedGuard } from '../guards';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { CalendlyComponent } from './calendly.component';
import { EmptyComponent } from './empty.component';

@NgModule({
    declarations: [AppComponent, CalendlyComponent, EmptyComponent],
    imports: [
        BrowserModule,
        UiModule,
        BrowserAnimationsModule,
        RouterModule,
        SharedModule,
        AppRoutingModule,
        DataModule,
        ToastrModule.forRoot({
            countDuplicates: true,
            preventDuplicates: true,
            resetTimeoutOnDuplicate: true,
            maxOpened: 5,
        }), // ToastrModule added
        FingerprintjsProAngularModule.forRoot({
            loadOptions: {
                apiKey: 'icVKtNvtbIY3JkgZyj6w',
                endpoint: `${environment.fpEndpointUrl}/BsbFofJFJvUkLyun/xfOZAu6KX83CSEvs`,
                scriptUrlPattern:
                    `${environment.fpEndpointUrl}/BsbFofJFJvUkLyun/8XRZpMMbaeslEyJZ?apiKey=<apiKey>&version=<version>&loaderVersion=<loaderVersion>`,
            },
        }),
    ],
    providers: [
        IsAuthenticatedGuard,
        { provide: APP_ENVIRONMENT, useValue: environment },
        {
            provide: APP_AUTOUPDATE,
            useFactory: (env: Environment) => {
                return env.autoUpdate;
            },
            deps: [APP_ENVIRONMENT],
        },
        {
            provide: APP_FPJS_TOKEN,
            useFactory: (auth: AuthService) => {
                return auth.getServerInfo().pipe(
                    map(result => {
                        return result?.fpjsApiToken || '';
                    }),
                );
            },
            deps: [AuthService],
        },
        { provide: APP_WINDOW, useFactory: () => window },
        { provide: APP_DOCUMENT, useFactory: () => document },
        { provide: HTTP_INTERCEPTORS, useClass: ApiVersionInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoginInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: DateInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: NoConnectionInterceptor, multi: true },
        ...(environment.production ? [{ provide: HTTP_INTERCEPTORS, useClass: CredentialsInterceptor, multi: true }] : []),
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(
        public appRef: ApplicationRef,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
    ) {
        console.log('@angular/core', ngVersion.full);
        console.log('@angular/material', ngMaterialVersion.full);

        Chart.register(
            // PieController,
            BarController,
            // ArcElement,
            CategoryScale,
            LinearScale,
            BarElement,
            Legend,
            Title,
            Tooltip,
            SubTitle,
        );

        this.matIconRegistry.addSvgIcon(
            'zoom_logo',
            this.domSanitizer.bypassSecurityTrustResourceUrl('https://cdn.shareablelink.online/production-public/apps/admin/assets/zoom-logo.svg'),
        );
    }
}
