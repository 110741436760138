import { Pipe, PipeTransform } from '@angular/core';
import { formatFileSize } from '../util';

@Pipe({
    name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
    transform(model: File | number | null): string {
        if (model === null) {
            return '';
        }

        if (model instanceof File) {
            return formatFileSize(model.size);
        }

        return formatFileSize(model);
    }
}
