import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { catchError, filter, mergeMap, Observable, take, throwError } from 'rxjs';

import { Environment } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';
import { Store } from '@ngxs/store';
import { AuthActions } from '@app/data/actions';
import { AuthState } from '@app/data/state';

@Injectable()
export class LoginInterceptor implements HttpInterceptor {
    ignoreUrls = ['/auth/token/me', '/ct'];
    constructor(@Inject(APP_ENVIRONMENT) private env: Environment, private store: Store) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (
            !this.ignoreUrls.some(url => req.url.includes(url)) &&
            (req.url.startsWith(`${this.env.serverUrl}`) ||
                req.url.startsWith(`${this.env.proxyServerUrl}`) ||
                req.url.startsWith(`${this.env.rapidApiUrl}`))
        ) {
            return next.handle(req).pipe(
                catchError((error: HttpErrorResponse) => {
                    if ((error instanceof HttpErrorResponse && error.status === 401) || error.status === 403) {
                        // handle 401/403 error
                        console.log('401/403 error', req.url);
                        // request user to login
                        this.store.dispatch(new AuthActions.NotAuthenticated('modal'));

                        return this.store.select(AuthState.isAuthenticated()).pipe(
                            filter(v => v === true),
                            take(1),
                            mergeMap(() => {
                                console.log('retrying request', req.url);
                                return next.handle(
                                    req.clone({
                                        setHeaders: {
                                            Authorization: '',
                                        },
                                    }),
                                );
                            }),
                        );
                    }

                    return throwError(() => error);
                }),
            );
        }

        return next.handle(req);
    }

    addHeaders(request: HttpRequest<any>, linkId: string): HttpRequest<any> {
        const headers: { [key: string]: string } = {};
        if (request.url.indexOf(this.env.serverUrl) === -1) {
            // request already has the header set, so fast return
            return request;
        }

        if (!request.headers.has('X-link-id') && linkId) {
            headers['X-link-id'] = linkId;
        }

        return request.clone({
            setHeaders: headers,
        });
    }
}
