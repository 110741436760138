import { ScreenProfile, ScreenOrientation } from '@app/shared/enums';
import { ClipboardModel } from '../models';

const ns = '[Core]';

export class CopyToClipboard {
    static readonly type = `${ns} Copy to clipboard`;
    constructor(public model: ClipboardModel) {}
}

export class UpdateOrientation {
    static readonly type = `${ns} Update Orientation`;
    constructor(public screenOrientation: ScreenOrientation) {}
}

export class ReportUserActivity {
    static readonly type = `${ns} Report User Activity`;
}

export class UpdateProfile {
    static readonly type = `${ns} Update Profile`;
    constructor(public screenProfile: ScreenProfile) {}
}

export class UpdateSideNavigation {
    static readonly type = `${ns} Update Side Navigation`;
    constructor(public isOpen: boolean) {}
}

export class ToggleSideNavigation {
    static readonly type = `${ns} Toggle Side Navigation`;
}

export class SetPageTitle {
    static readonly type = `${ns} Set Page Title`;
    constructor(public title: string | null) {}
}

export class UpdateGlobalLoading {
    static readonly type = `${ns} Update Global Loading`;
    constructor(public isLoading: boolean) {}
}
export class UserIsIdle {
    static readonly type = `${ns} User is idle`;
}
export class UserIsActive {
    static readonly type = `${ns} User is active`;
}
export class MarkUserActivity {
    static readonly type = `${ns} Mark User Activity`;
}

export class StartAutoRefresh {
    static readonly type = `${ns} Start Auto Refresh`;
}

export class Refresh {
    static readonly type = `${ns} Refresh`;
}

export class SessionActive {
    static readonly type = `${ns} Session Active`;
}

export class UpdateConnectionStatus {
    static readonly type = `${ns} Update Connection Status`;
    constructor(public isConnected: boolean) {}
}
