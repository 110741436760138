import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CoreActions } from '@app/data/actions';
import { Environment } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';
import { Store } from '@ngxs/store';
import { catchError, Observable, tap, throwError } from 'rxjs';

@Injectable()
export class NoConnectionInterceptor implements HttpInterceptor {
    constructor(@Inject(APP_ENVIRONMENT) private env: Environment, private store: Store) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (
            req.url.startsWith(`${this.env.serverUrl}`) ||
            req.url.startsWith(`${this.env.proxyServerUrl}`) ||
            req.url.startsWith(`${this.env.rapidApiUrl}`)
        ) {
            return next.handle(req).pipe(
                tap(() => {
                    this.store.dispatch(new CoreActions.UpdateConnectionStatus(true));
                }),
                catchError((error: HttpErrorResponse) => {
                    if (error instanceof HttpErrorResponse && error.status === 0) {
                        // handle 401/403 error
                        this.store.dispatch(new CoreActions.UpdateConnectionStatus(false));
                    }

                    return throwError(() => error);
                }),
            );
        }

        return next.handle(req);
    }
}
