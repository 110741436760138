<mat-accordion>
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title><mat-icon>bug_report</mat-icon> Debug </mat-panel-title>
        </mat-expansion-panel-header>

        <section>
            <mat-list dense>
                <mat-list-item *ngFor="let message of logs">{{ message }}</mat-list-item>
                <mat-divider></mat-divider>
            </mat-list>
        </section>
        <mat-action-row>
            <button mat-raised-button color="accent" (click)="onClear()">Clear</button>
            <button mat-raised-button color="accent" (click)="onCopy()">Copy</button>
        </mat-action-row>
    </mat-expansion-panel>
</mat-accordion>
