import { ActivatedRouteSnapshot, Params, RouterStateSnapshot } from '@angular/router';

import { RouterState as NgxsRouterState, RouterStateModel } from '@ngxs/router-plugin';
import { createSelector } from '@ngxs/store';

export class RouterState {
    static selectCurrentRoute() {
        return createSelector([NgxsRouterState], (model: RouterStateModel<RouterStateSnapshot>) => {
            if (!model || !model.state) {
                return undefined;
            }

            let route = model.state.root;
            while (route.firstChild) {
                route = route.firstChild;
            }

            return route;
        });
    }

    static selectRouteParams(root?: ActivatedRouteSnapshot) {
        return createSelector([NgxsRouterState], (model: RouterStateModel<RouterStateSnapshot>) => {
            if (!root && (!model || !model.state)) {
                return undefined;
            }

            let route = root || model.state?.root;
            let params = { ...route?.params };
            while (route?.firstChild) {
                route = route.firstChild;
                params = { ...params, ...route.params };
            }

            return params;
        });
    }

    static selectRouteParam(param: string, root?: ActivatedRouteSnapshot) {
        return createSelector([RouterState.selectRouteParams(root)], (params: Params) => {
            return params[param];
        });
    }

    static selectQueryParams() {
        return createSelector([NgxsRouterState], (model: RouterStateModel<RouterStateSnapshot>) => {
            if (!model || !model.state) {
                return undefined;
            }

            let route = model.state.root;
            let queryParams = { ...route.queryParams };
            while (route.firstChild) {
                route = route.firstChild;
                queryParams = { ...queryParams, ...route.queryParams };
            }

            return queryParams;
        });
    }

    static selectQueryParam(param: string) {
        return createSelector([RouterState.selectQueryParams()], (params: Params) => {
            return params[param];
        });
    }

    static selectRouteData() {
        return createSelector([RouterState.selectCurrentRoute()], (route: ActivatedRouteSnapshot) => {
            return route.data;
        });
    }
}
