import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'indicatorIcon',
})
export class IndicatorIconPipe implements PipeTransform {
    transform(value: Record<string, string> | null | undefined): string {
        if (value) {
            const key = Object.keys(value).find(s => s.toLocaleLowerCase() === 'indicatoricon');

            if (key) {
                return value[key];
            }
        }

        return '';
    }
}
