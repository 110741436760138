import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { NodeType, SearchResultType } from '@app/shared/enums';
import { Environment, ServerResult } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';
import { getPath } from '@app/shared/util';
import { addStopWords, clearStopWords, Index } from 'elasticlunr';

import { LinkSearchResultModel, Node, NoteModel, QueryResult, SearchByName, SearchResult } from '../models';
import { errorHandler, getData, verifyServerResult } from '../operators';
import { STOP_WORDS } from './stop-words';

export interface SearchNode {
    rowKey: string | null;
    path: string;
    title: string;
    isLoaded: boolean;
    content: string | null;
    type: NodeType;
}

@Injectable({
    providedIn: 'root',
})
export class SearchService {
    static index: Index<Node>;

    constructor(@Inject(APP_ENVIRONMENT) private env: Environment, private http: HttpClient) {}

    searchLinksByName(model: SearchByName): Observable<QueryResult<LinkSearchResultModel>> {
        return this.http
            .post<ServerResult<QueryResult<LinkSearchResultModel>>>(`${this.env.serverUrl}/manage/links/search`, model)
            .pipe(verifyServerResult(), errorHandler(), getData());
    }

    searchFormsByName(model: SearchByName): Observable<QueryResult<LinkSearchResultModel>> {
        return this.http
            .post<ServerResult<QueryResult<LinkSearchResultModel>>>(`${this.env.serverUrl}/manage/forms/search`, model)
            .pipe(verifyServerResult(), errorHandler(), getData());
    }

    updateIndex(): Observable<void> {
        return this.http.post<void>(
            `https://shareablelink.online/worker/queue/${this.env.searchIndexQueueName}`,
            {},
            {
                headers: {
                    'Access-Control-Allow-Method': 'POST',
                },
            },
        );
    }

    loadIndex(state: any): void {
        clearStopWords();
        addStopWords(STOP_WORDS);
        const index = Index.load(state) as Index<Node>;

        SearchService.index = index;
    }

    search(nodes: SearchNode[], notes: NoteModel[], term: string, opt?: any): SearchResult[] {
        const results = SearchService.index.search(term, opt);
        const searchResults: SearchResult[] = [];

        results.forEach((ixResult: { score: number; ref: string }) => {
            if (ixResult.ref.startsWith('node')) {
                const result = this.processNode(term, nodes, ixResult.ref, ixResult.score);
                if (result) {
                    searchResults.push(result);
                }
            }

            if (ixResult.ref.startsWith('note')) {
                const result = this.processNote(term, notes, ixResult.ref, ixResult.score);
                if (result) {
                    searchResults.push(result);
                }
            }
        });

        return searchResults;
    }

    processNote(term: string, notes: NoteModel[], ref: string, score: number) {
        const userId = ref.split('-')[1];
        const rowKey = ref.split('-')[2];
        const note = notes.find(n => n.rowKey === `${userId}-${rowKey}`);

        if (!note) {
            return null;
        }

        const result: SearchResult = {
            score,
            path: null,
            data: note,
            type: SearchResultType.Note,
            keywords: new Map<string, number | null>(),
            tokens: null,
        } as any;

        if (note.isLoaded) {
            let content = ((note.content as any) || '').toLowerCase();

            const div = document.createElement('div');
            div.innerHTML = note.content as any;
            content = div.textContent;

            if (content) {
                const pattern = new RegExp(term, 'gi');
                const regex = content.match(pattern);
                if (regex && regex.length > 0) {
                    result.tokens = this.getTokens(term, content, regex);
                }

                const tokens = this.parseKeywords(term);
                const keywords = this.parseKeywords(content);
                const keys = Array.from(keywords.keys());
                Array.from(tokens.keys())
                    .filter(w => keys.some(k => k === w))
                    .forEach(keyword => {
                        result.keywords.set(keyword, keywords.get(keyword) || null);
                    });
            }
        }

        return result;
    }

    processNode(term: string, nodes: SearchNode[], ref: string, score: number) {
        const rowKey = ref.split('-')[1];
        const node = nodes.find(n => n.rowKey === rowKey);

        if (!node) {
            return null;
        }

        const path = getPath(node, nodes).join('/');

        const result: SearchResult = {
            path,
            score,
            data: node,
            type: SearchResultType.Node,
            keywords: new Map<string, number>(),
            tokens: null,
        } as any;

        if (node.isLoaded) {
            let content = ((node.content as any) || '').toLowerCase();

            switch (node.type) {
                case NodeType.Html:
                case NodeType.Audio:
                case NodeType.File:
                case NodeType.Video:
                    {
                        const div = document.createElement('div');
                        div.innerHTML = node.content as any;
                        content = div.textContent;
                        // partial matching is not supported. What would be a valid work around?
                    }
                    break;
                default:
                    break;
            }

            if (content) {
                const pattern = new RegExp(term, 'gi');
                const regex = content.match(pattern);
                if (regex && regex.length > 0) {
                    result.tokens = this.getTokens(term, content, regex);
                }

                const tokens = this.parseKeywords(term);
                const keywords = this.parseKeywords(content);
                const keys = Array.from(keywords.keys());
                Array.from(tokens.keys())
                    .filter(w => keys.some(k => k === w))
                    .forEach(keyword => {
                        result.keywords.set(keyword, keywords.get(keyword) || null);
                    });
            }
        }

        return result;
    }

    getTokens(text: string, content: string, match: RegExpMatchArray, limit = 3): string[] {
        const result: string[] = [];
        let lastIx = 0;

        for (let x = 0; x < Math.min(limit, match.length); x++) {
            const ix = content.indexOf(text, lastIx);
            const len = content.length;
            const max = ix + text.length + 30;
            const min = Math.max(0, ix - 10);
            const start = min === 0 ? '' : '...';
            const end = max >= len ? '' : '...';
            lastIx = ix + text.length;

            result.push(start + content.substring(min, max) + end);
        }

        return result;
    }

    buildKeyWords(content: Node[]): void {
        content.forEach(current => {
            current.keywords = this.parseKeywords(current.content as any);
        });
    }

    parseKeywords(text: string): Map<string, number> {
        const regex = /\w+/gi;
        const words = text.match(regex) || [];
        return Array.from(words)
            .map(word => word.toLowerCase())
            .filter(word => word.length > 1 && !STOP_WORDS.some(sw => sw === word))
            .reduce((prev, curr, ix, self) => {
                if (prev.has(curr)) {
                    prev.set(curr, prev.get(curr) + 1);
                } else {
                    prev.set(curr, 1);
                }
                return prev;
            }, new Map());
    }
}
