import { FlatTreeControl } from '@angular/cdk/tree';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    TemplateRef,
} from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';

import { take, tap } from 'rxjs/operators';

import { BaseNode, ShareableLinkModel } from '@app/data/models';
import { NodeType } from '@app/shared/enums';
import { containsPath, sortNodes } from '@app/shared/util';

import { ToggleMode } from '../../enums';

interface TreeNode {
    id: string;
    name: string;
    data: BaseNode;
    parentId: string | null;
    children: TreeNode[];
}

interface FlatNode {
    expandable: boolean;
    data: BaseNode;
    name: string;
    level: number;
    id: string;
}

@Component({
    selector: 'ui-node-tree',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: `./node-tree.component.html`,
    styleUrls: [`./node-tree.component.scss`],
})
export class NodeTreeComponent implements OnChanges {
    NodeType: typeof NodeType = NodeType;
    ToggleMode: typeof ToggleMode = ToggleMode;

    @Input() isLoading: boolean | null = false;
    @Input() path: string | null = null;
    @Input() nodes: BaseNode[] | null = [];
    @Input() links: ShareableLinkModel[] | null = [];
    @Input() expandedNodeIds: string[] | null = null;
    @Input() isNodeContentLoading: boolean | null = false;
    @Input() toggleMode: ToggleMode = ToggleMode.ScopeToNode;

    @Input() actionsTemplate: TemplateRef<any> | null = null;
    @Input() labelTemplate: TemplateRef<any> | null = null;
    @Input() nodeTemplate: TemplateRef<any> | null = null;
    @Input() nodeTemplateContext: unknown | null = null;

    @Output() readonly viewNode: EventEmitter<BaseNode> = new EventEmitter();
    @Output() readonly selectNode: EventEmitter<BaseNode | null> = new EventEmitter();
    @Output() readonly updatePath: EventEmitter<string | null> = new EventEmitter();
    @Output() readonly expandNode: EventEmitter<string> = new EventEmitter();
    @Output() readonly collapseNode: EventEmitter<string> = new EventEmitter();

    nodeDataSource!: MatTreeFlatDataSource<TreeNode, FlatNode>;
    treeControl = new FlatTreeControl<FlatNode>(
        node => node.level,
        node => node.expandable,
    );
    treeFlattener = new MatTreeFlattener<TreeNode, FlatNode, FlatNode>(
        this.transformer,
        node => node.level,
        node => node.expandable,
        node => node.children,
    );

    hasChild(_: number, node: FlatNode) {
        return node.expandable;
    }

    isExpanded(node: FlatNode) {
        return this.treeControl.isExpanded(node);
    }

    transformer(node: TreeNode, level: number) {
        return {
            node,
            level,
            expandable: node.children.length > 0,
            name: node.name,
            data: node.data,
            id: node.id,
        };
    }

    updateNodeDataSource(path: string | null = null) {
        const nodes: TreeNode[] = (this.nodes || [])
            .filter(n => path === null || containsPath(n.path, path))
            .sort(sortNodes)
            .map(node => ({
                id: node.rowKey,
                name: node.title,
                parentId: node.parentId,
                children: [],
                data: node,
            }))
            .reduce((acc: TreeNode[], node, ix, arr) => {
                const parent = arr.find(n => n.id === node.parentId) as TreeNode | undefined;

                if (parent) {
                    parent.children.push(node);
                } else {
                    acc.push(node);
                }

                return acc;
            }, [] as TreeNode[]);

        this.nodeDataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener, []);
        this.nodeDataSource.data = nodes;

        let expanded!: FlatNode[];

        (this.nodeDataSource as any)._flattenedData
            .pipe(
                take(1),
                tap((nodes: FlatNode[]) => (expanded = nodes)),
            )
            .subscribe();

        this.treeControl.expansionModel.select(...expanded.filter(n => this.expandedNodeIds?.some(id => id === n.id)));
    }

    setRoot(node: BaseNode | null) {
        this.updatePath.emit(node ? (node?.path ? `${node.path}.${node.rowKey}` : node.rowKey) : null);
        this.selectNode.emit(node);
    }

    toggleNode(node: FlatNode) {
        if (this.treeControl.isExpanded(node)) {
            this.expandNode.emit(node.id);
        } else {
            this.collapseNode.emit(node.id);
        }
    }

    onViewNode(node: BaseNode) {
        this.viewNode.emit(node);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['nodes'] && changes['nodes'].currentValue) {
            this.nodes = this.nodes?.map(n => ({ ...n })) || [];
            this.updateNodeDataSource(this.path);
        }

        if (changes['path']) {
            this.updateNodeDataSource(this.path);
        }
    }
}
