import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';

import { EMPTY, finalize, Observable, tap } from 'rxjs';

import { Environment } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';

export interface DownloadSectionModel {
    id: string;

    title: string;
    description: string;
    fileName: string | null;
    isRequired: boolean;
    value: any;
}

@Component({
    selector: 'ui-form-section-download',
    templateUrl: './form-section-download.component.html',
    styleUrls: ['./form-section-download.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSectionDownloadComponent {
    downloadClicked = false;
    isDownloading = false;

    get showTitle() {
        return this.model && this.model.title;
    }

    get title() {
        return this.model?.title;
    }

    get isRequired() {
        return this.model?.isRequired;
    }

    get hasDownloaded() {
        return this.model?.value === true || this.downloadClicked;
    }

    @Input() model: DownloadSectionModel | null = null;
    @Input() readonly = false;

    @Output() readonly download: EventEmitter<void> = new EventEmitter();

    constructor(
        @Inject(APP_ENVIRONMENT) private env: Environment,
        private cd: ChangeDetectorRef,
        private http: HttpClient,
    ) {}

    onDownload(): Observable<unknown> {
        if (this.isDownloading) {
            return EMPTY;
        }

        this.isDownloading = true;
        this.downloadClicked = true;

        this.cd.detectChanges();

        let uri: string | null = null;
        const link = document.createElement('a');

        return this.http
            .post(
                `${this.env.serverUrl}/form/binary`,
                { sectionId: this.model?.id },
                {
                    observe: 'response',
                    responseType: 'arraybuffer',
                },
            )
            .pipe(
                tap(response => {
                    const blob = new Blob([response.body as ArrayBuffer], { type: 'application/octet-binary' });
                    uri = URL.createObjectURL(blob);
                    link.setAttribute('href', uri);
                    link.setAttribute('download', `${this.model?.fileName}`);
                    document.body.appendChild(link);
                    link.click();
                }),
                finalize(() => {
                    URL.revokeObjectURL(uri as string);
                    document.body.removeChild(link);
                    this.isDownloading = false;
                    this.cd.detectChanges();
                    this.download.emit();
                }),
            );
    }
}
