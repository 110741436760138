<h2 matDialogTitle><mat-icon>payments</mat-icon> Make a Payment</h2>
<mat-dialog-content>
    <h3>Amount: {{ data.amount | currency : data.merchantCurrency }}</h3>

    <mat-stepper #stepper linear orientation="vertical">
        <mat-step [stepControl]="form">
            <ng-template matStepLabel>Recipient Info</ng-template>
            <section class="info" [formGroup]="form">
                <div>
                    <mat-form-field fxFlex appearance="outline">
                        <mat-label>Name</mat-label>
                        <input name="name" matInput formControlName="name" cdkFocusInitial />
                        <mat-error *ngIf="name.invalid">
                            <shared-error-display [error]="name.errors"></shared-error-display>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field fxFlex appearance="outline">
                        <mat-label>Email</mat-label>
                        <mat-hint>Used to email a receipt</mat-hint>
                        <input name="email" matInput formControlName="email" />
                        <mat-error *ngIf="email.invalid">
                            <shared-error-display [error]="email.errors"></shared-error-display>
                        </mat-error>
                    </mat-form-field>
                </div>
            </section>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Payment Info</ng-template>
            <div class="payment-form" #payment>Loading...</div>
        </mat-step>
    </mat-stepper>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <shared-error-display class="error-display" [error]="error"></shared-error-display>
    <button mat-button *ngIf="isFirstStep" mat-dialog-close>Cancel</button>
    <button mat-button *ngIf="!isFirstStep" [disabled]="isLoading" (click)="onNavigateToStepIndex(0)">
        <mat-icon>arrow_back</mat-icon> Back
    </button>
    <button mat-raised-button *ngIf="isFirstStep" [disabled]="form.invalid" (click)="onNavigateToStepIndex(1)">
        Next<mat-icon>arrow_forward</mat-icon>
    </button>
    <button mat-raised-button *ngIf="!isFirstStep" [disabled]="isLoading" color="primary" (click)="onSubmit()">
        <mat-spinner *ngIf="isLoading" matPrefix diameter="20"></mat-spinner>
        Submit
    </button>
</mat-dialog-actions>
