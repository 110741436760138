import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <h2 matDialogTitle><mat-icon>open_in_new</mat-icon> Link has migrated</h2>
        <mat-dialog-content>
            <p>
                Good news! This link has been migrated to the new domain. Make sure that you update any bookmarks. Please
                click the link below to continue.
            </p>

            <p>
                <a [href]="link" rel="nofollow" target="_blank">{{ text }}</a>
            </p>
        </mat-dialog-content>
    `,
    styles: [
        `
            mat-icon {
                vertical-align: sub;
            }
        `,
    ],
})
export class RedirectMigrationDialogComponent {
    link: string;
    text: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
        const { thumbprint } = data;
        const url = new URL(window.location.href);
        const { origin } = url;

        this.text = url.href.replace(origin, 'https://shareablelink.online');

        url.searchParams.set('mid', thumbprint);

        this.link = url.href.replace(origin, 'https://shareablelink.online');
    }
}
