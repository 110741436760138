import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterModule } from '@angular/router';

import { BuildInfoComponent, ConnectionStatusComponent, ErrorDisplayComponent, MiniPlayerComponent, TiptapMediaAssetComponent, ToastrProgressComponent } from './components';
import { MiniPlayerContainerComponent, TiptapImageContainerComponent, TiptapVideoContainerComponent } from './containers';
import {
    ElementDirective,
    FocusDirective,
    OutsideClickDirective,
    PreviewDirective,
    SrcUnloadDirective,
    ViewportCheckDirective,
} from './directives';
import { PipesModule } from './pipes';
import { MediaAssetPlayerComponent } from './components';
import { StripePaymentDialogComponent } from './dialogs';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

const DECLARATIONS = [
    ElementDirective,
    PreviewDirective,
    FocusDirective,
    OutsideClickDirective,
    ViewportCheckDirective,
    SrcUnloadDirective,

    MiniPlayerComponent,
    MiniPlayerContainerComponent,
    ToastrProgressComponent,

    TiptapImageContainerComponent,
    TiptapVideoContainerComponent,

    MediaAssetPlayerComponent,
    TiptapMediaAssetComponent,

    StripePaymentDialogComponent,
    ErrorDisplayComponent,

    BuildInfoComponent,
    ConnectionStatusComponent,
];

const MATERIAL = [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatStepperModule,
    MatInputModule,
    MatFormFieldModule,
];

@NgModule({
    declarations: [...DECLARATIONS],
    imports: [...MATERIAL, PipesModule, ReactiveFormsModule, RouterModule, CommonModule],
    exports: [...DECLARATIONS, PipesModule],
})
export class SharedModule {}
