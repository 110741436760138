import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    Output,
} from '@angular/core';
import { dhms } from '@app/shared/util';
import { interval, Subject } from 'rxjs';
import { filter, map, startWith, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'ui-count-down',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: `./count-down.component.html`,
    styleUrls: [`./count-down.component.scss`],
})
export class CountDownComponent implements OnDestroy {
    isAlive$ = new Subject<void>();

    days = 0;
    hours = 0;
    minutes = 0;
    seconds = 0;

    @Input() startDate: Date | null = null;
    @Output() readonly countDown: EventEmitter<void> = new EventEmitter();

    countDown$ = interval(1000)
        .pipe(
            startWith(0),
            takeUntil(this.isAlive$),
            map(x => (this.startDate ? Math.floor((this.startDate.getTime() - new Date().getTime()) / 1000) : 0)),
            filter(x => x >= 0),
            tap(x => {
                const [days, hours, minutes, seconds] = dhms(x);

                this.zone.run(() => {
                    this.days = days;
                    this.hours = hours;
                    this.minutes = minutes;
                    this.seconds = seconds;

                    if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
                        this.countDown.emit();
                    }

                    this.cd.markForCheck();
                });
            }),
        )
        .subscribe();

    constructor(private cd: ChangeDetectorRef, private zone: NgZone) {}

    ngOnDestroy() {
        this.isAlive$.next();
        this.isAlive$.complete();
    }
}
