/* eslint-disable no-restricted-syntax */
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CoreActions, InsightActions } from '@app/data/actions';
import { Environment } from '@app/shared/models';
import { APP_DEBUG, APP_ENVIRONMENT } from '@app/shared/tokens';
import { Action, createSelector, NgxsOnInit, State, StateContext, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { DebugActions } from '../actions';

export interface StateModel {
    isEnabled: boolean;
    thumbprint: string | null;
    visitorId: string | null;
    logs: string[];
}

@State<StateModel>({
    name: 'debug',
    defaults: {
        isEnabled: false,
        thumbprint: null,
        visitorId: null,
        logs: [],
    },
})
@Injectable()
export class DebugState implements NgxsOnInit {
    static getLogs() {
        return createSelector([DebugState], (model: StateModel) => model.logs);
    }

    static getThumbprint() {
        return createSelector([DebugState], (model: StateModel) => model.thumbprint);
    }

    static getVisitorId() {
        return createSelector([DebugState], (model: StateModel) => model.visitorId);
    }

    static isEnabled() {
        return createSelector([DebugState], (model: StateModel) => model.isEnabled);
    }

    constructor(
        private store: Store,
        private router: Router,
        @Inject(APP_ENVIRONMENT) private env: Environment,
        @Inject(APP_DEBUG) private debug: boolean,
    ) {}

    ngxsOnInit(ctx?: StateContext<StateModel>) {
        const isProduction = this.env.production === true;

        if (!isProduction && location.search.includes('tp=')) {
            const thumbprint = location.search
                .split('&')
                .find(s => s.includes('tp='))
                ?.split('=');

            ctx?.patchState({
                thumbprint: thumbprint && !isProduction ? thumbprint[1] || null : null,
            });
        }

        if (!isProduction && location.search.includes('vid=')) {
            const visitorId = location.search
                .split('&')
                .find(s => s.includes('vid='))
                ?.split('=');

            ctx?.patchState({
                visitorId: visitorId && !isProduction ? visitorId[1] || null : null,
            });
        }

        ctx?.patchState({
            isEnabled: (this.debug || location.search.includes('debug')) === true,
        });
    }

    @Action(DebugActions.Clear)
    clear({ patchState }: StateContext<StateModel>) {
        patchState({ logs: [] });
    }

    @Action(DebugActions.Add)
    add({ patchState, getState }: StateContext<StateModel>, { message }: DebugActions.Add) {
        const logs = [...getState().logs];
        patchState({ logs: logs.concat([message]) });
    }

    @Action(DebugActions.CopyLogs)
    copyLogs({ dispatch, getState }: StateContext<StateModel>) {
        const logs = [...getState().logs];
        return dispatch(new CoreActions.CopyToClipboard({ text: logs.join('\n\r'), message: 'Copied!' }));
    }

    @Action(InsightActions.TrackEvent)
    trackEvent({ patchState, getState }: StateContext<StateModel>, action: InsightActions.TrackEvent) {
        const isEnabled = this.store.selectSnapshot(DebugState.isEnabled());

        if (action.props) {
            console.debug(action.event.name, action.props);
        } else {
            console.debug(action.event.name);
        }

        if (!isEnabled) {
            return;
        }

        const logs = [...getState().logs];
        patchState({ logs: logs.concat([action.event.name]) });
    }

    @Action(InsightActions.TrackDebugEvent)
    onTrackDebugEvent({ dispatch }: StateContext<StateModel>, action: InsightActions.TrackDebugEvent): Observable<any> | void {
        const isEnabled = this.store.selectSnapshot(DebugState.isEnabled());

        if (action.props) {
            console.debug(action.event.name, action.props);
        } else {
            console.debug(action.event.name);
        }

        if (!isEnabled) {
            return;
        }

        return dispatch(new InsightActions.TrackEvent(action.event, action.props));
    }

    @Action(InsightActions.SetAuthenticationContext)
    setAuthenticationContext(
        { patchState, getState }: StateContext<StateModel>,
        action: InsightActions.SetAuthenticationContext,
    ) {
        const isEnabled = this.store.selectSnapshot(DebugState.isEnabled());

        if (!isEnabled) {
            return;
        }
        const logs = [...getState().logs];
        patchState({ logs: logs.concat([`Auth context id: ${action.id}`]) });
    }
}
