<ng-container *ngIf="src" [ngSwitch]="display">
    <div class="player" [ngStyle]="getStyling()" *ngSwitchCase="'inline'">
        <div class="title">{{ title }}</div>
        <mat-icon class="play-button" matRipple *ngIf="!hasInteracted" (click)="onPlayMedia()">{{
            isPlayingMedia ? 'pause' : 'play_arrow'
        }}</mat-icon>

        <div
            matRipple
            *ngIf="hasInteracted"
            (click)="onPlayMedia()"
            class="music-bars play-button"
            [ngClass]="{ paused: !isPlayingMedia }"
        >
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
    <div [ngStyle]="getStyling()" *ngSwitchDefault>
        <audio controls controlsList="nodownload" preload="none">Your browser does not support the audio tag.</audio>
    </div>
</ng-container>

<ng-container *ngIf="isLoading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-container>
