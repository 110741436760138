<div>
    <div class="title">{{ title }}</div>
    <div class="player">
        <button *ngIf="canSeek" [disabled]="!isPlaying" mat-icon-button (click)="onSeek(-10)">
            <mat-icon>replay_10</mat-icon>
        </button>
        <button mat-mini-fab [disabled]="!canPlay" (click)="onPlay()">
            <mat-icon>{{ isPlaying ? 'pause' : 'play_arrow' }}</mat-icon>
        </button>
        <button *ngIf="canSeek" [disabled]="!isPlaying" mat-icon-button (click)="onSeek(10)">
            <mat-icon>forward_10</mat-icon>
        </button>
        <button [disabled]="!isPlaying" (click)="onChangePlaybackRate()" mat-mini-fab>
            x{{ playbackRate | number : '1.1' }}
        </button>
    </div>
    <time>{{ position | elapsedTime }} / {{ duration | elapsedTime }}</time>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
