import {
    IPageViewTelemetry,
    IEventTelemetry,
    IMetricTelemetry,
    IExceptionTelemetry,
    IDependencyTelemetry,
} from '@microsoft/applicationinsights-web';
import { ICustomProperties } from '@microsoft/applicationinsights-core-js';

export class TrackPageView {
    static readonly type = '[Insights] Track Page View';
    constructor(public event?: IPageViewTelemetry) {}
}

export class TrackEvent {
    static readonly type = '[Insights] Track Event';
    constructor(public event: IEventTelemetry, public props?: ICustomProperties) {}
}
export class TrackMetric {
    static readonly type = '[Insights] Track Metric';
    constructor(public event: IMetricTelemetry, public props?: ICustomProperties) {}
}
export class TrackDebugEvent {
    static readonly type = '[Insights] Track Debug Event';
    constructor(public event: IEventTelemetry, public props?: ICustomProperties) {}
}

export class TrackException {
    static readonly type = '[Insights] Track Exception';
    constructor(public ex: IExceptionTelemetry) {}
}
export class TrackDependency {
    static readonly type = '[Insights] Track Dependency';
    constructor(public event: IDependencyTelemetry) {}
}
export class SetAuthenticationContext {
    static readonly type = '[Insights] Set Authentication Context';
    constructor(public id: string) {}
}
export class ClearAuthenticationContext {
    static readonly type = '[Insights] Clear Authentication Context';
}
