import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

import { CurrentUser, SearchResult } from '@app/data/models';
import { Layout, SearchResultType } from '@app/shared/enums';

const DEBOUNCE_IN_MS = 300;

@Component({
    selector: 'ui-search',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy {
    private isAlive$: Subject<void> = new Subject();

    Layout: typeof Layout = Layout;
    SearchResultType: typeof SearchResultType = SearchResultType;

    form: FormGroup = new FormGroup({
        search: new FormControl(''),
    });

    get search(): AbstractControl {
        return this.form.controls['search'];
    }

    @Input() showBadge: boolean | null = false;
    @Input() badgeColor: string | null = '';
    @Input() badgeText: string | null = '';
    @Input() isSearchEnabled: boolean | null = true;
    @Input() showSearch: boolean | null = true;
    @Input() currentUser: CurrentUser | null = null;
    @Input() searchResults: SearchResult[] | null = null;

    @Output() readonly searchText: EventEmitter<string> = new EventEmitter();
    @Output() readonly selectNode: EventEmitter<SearchResult> = new EventEmitter();
    @Output() readonly toggleNavigation: EventEmitter<void> = new EventEmitter();

    onSelectNode(result: SearchResult): void {
        this.selectNode.emit(result);
    }

    ngOnInit(): void {
        this.search.valueChanges
            .pipe(
                takeUntil(this.isAlive$),
                debounceTime(DEBOUNCE_IN_MS),
                tap(s => {
                    this.searchText.emit(s);
                }),
            )
            .subscribe();
    }

    onToggleNavigation(): void {
        this.toggleNavigation.emit();
    }

    ngOnDestroy(): void {
        this.isAlive$.next();
        this.isAlive$.complete();
    }
}
