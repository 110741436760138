<ng-container *ngIf="event; else loading">
    <mat-list>
        <ng-container *ngIf="isActive">
            <mat-list-item>
                <mat-icon matListItemIcon>event</mat-icon>
                <div matListItemTitle>{{ event.eventName }}</div>

                <div matListItemLine>Event will begin at</div>
                <div matListItemLine class="text">
                    <b>{{ formatDate(event.startTime) }}</b>
                </div>
                <div matListItemLine>Event ends at</div>
                <div matListItemLine>
                    <b>{{ formatDate(event.endTime) }}</b>
                </div>
            </mat-list-item>
            <mat-divider></mat-divider>
            <ng-container [ngSwitch]="event.location?.type">
                <mat-list-item *ngSwitchCase="'zoom'">
                    <mat-icon matListItemIcon>near_me</mat-icon>
                    <div matListItemTitle>Zoom Meeting</div>
                    <div matListItemLine *ngIf="event.location?.join_Url as url">
                        <a [href]="url" rel="nofollow" target="_blank">{{ url }}</a>
                    </div>
                </mat-list-item>
                <mat-list-item *ngSwitchCase="'outbound_call'">
                    <mat-icon matListItemIcon>near_me</mat-icon>
                    <div matListItemTitle>Outbound Call</div>
                    <div matListItemLine>{{ event.location?.location }}</div>
                </mat-list-item>
                <mat-list-item *ngSwitchCase="'inbound_call'">
                    <mat-icon matListItemIcon>near_me</mat-icon>
                    <div matListItemTitle>Inbound Call</div>
                    <div matListItemLine>{{ event.location?.location }}</div>
                </mat-list-item>
                <mat-list-item *ngSwitchCase="'physical'">
                    <mat-icon matListItemIcon>near_me</mat-icon>
                    <div matListItemTitle>In-person</div>
                    <div matListItemLine>{{ event.location?.location }}</div>
                </mat-list-item>
                <mat-list-item *ngSwitchCase="'ask_invitee'">
                    <mat-icon matListItemIcon>near_me</mat-icon>
                    <div matListItemTitle>Custom</div>
                    <div matListItemLine>{{ event.location?.location }}</div>
                </mat-list-item>
                <mat-list-item *ngSwitchCase="'custom'">
                    <mat-icon matListItemIcon>near_me</mat-icon>
                    <div matListItemTitle>Custom</div>
                    <div matListItemLine>{{ event.location?.location }}</div>
                </mat-list-item>
            </ng-container>
            <ng-container *ngFor="let invitee of event.invitees">
                <mat-divider></mat-divider>
                <mat-list-item>
                    <mat-icon matListItemIcon>person</mat-icon>
                    <div matListItemTitle>{{ invitee.name || invitee.firstName + ' ' + invitee.lastName }}</div>
                    <div matListItemLine>{{ invitee.email }}</div>
                </mat-list-item>

                <ng-container *ngIf="invitee.questionsAndAnswers | any">
                    <mat-divider></mat-divider>
                    <mat-list-item>
                        <mat-icon matListItemIcon>help</mat-icon>
                        <div matListItemTitle>Questions & Answers</div>
                    </mat-list-item>
                    <mat-list-item *ngFor="let result of invitee.questionsAndAnswers">
                        <mat-icon matListItemIcon></mat-icon>
                        <div matListItemTitle>{{ result.question }}</div>
                        <div matListItemLine>{{ result.answer }}</div>
                        <!-- <div matListItem>&nbsp;</div> -->
                    </mat-list-item>
                </ng-container>
            </ng-container>
        </ng-container>
        <mat-list-item *ngIf="isCancelled">
            <mat-icon matListItemIcon color="warn">event_busy</mat-icon>
            <div matListItemTitle>Event has been cancelled by {{ event.cancellation?.canceledBy }}</div>

            <div matListItemLine>{{ event.cancellation?.reason }}</div>
        </mat-list-item>
    </mat-list>
</ng-container>

<ng-template #loading>
    <mat-spinner></mat-spinner>
</ng-template>
