import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'highlight',
    pure: false,
})
export class HighlightPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(value: string, ...args: any[]): SafeHtml {
        const term: string = args ? args[0] : '';
        let result = value;

        if (term === '') {
            return result;
        }

        const len = term.length;
        const regex = new RegExp(term, 'gi');

        const matches: RegExpExecArray[] = [];
        let match = regex.exec(result);

        while (match !== null) {
            matches.push(match);
            match = regex.exec(result);
        }

        matches.reverse().forEach(m => {
            const end = m.index + len;
            const text = `<span class="highlight">${m[0]}</span>`;
            result = result.substring(0, m.index) + text + result.substring(end);
        });

        return this.sanitizer.bypassSecurityTrustHtml(result);
    }
}
