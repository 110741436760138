import { Pipe, PipeTransform } from "@angular/core";
import { PaymentStatus } from "../enums";

@Pipe({
    name: 'paymentStatus',
})
export class PaymentStatusPipe implements PipeTransform {
    transform(value: PaymentStatus): string {

        switch (value) {
            case PaymentStatus.None:
                return 'NONE';
            case PaymentStatus.Cancelled:
                return 'CANCELLED';
            case PaymentStatus.Pending:
                return 'PENDING';
            case PaymentStatus.Complete:
                return 'COMPLETE';
            case PaymentStatus.Refunded:
                return 'REFUNDED';
            default:
                return '';
        }
    }
}