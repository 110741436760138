<mat-card>
    <mat-card-header>
        <mat-card-title *ngIf="showTitle">{{ title }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ui-rich-text-view [content]="model.description"></ui-rich-text-view>
        <!-- <p>Meeting registration for: {{ registrant?.topic }}</p> -->
        <div *ngIf="!joinUrl; else registered">
            <div [formGroup]="form">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>First Name</mat-label>
                    <input formControlName="firstName" matInput />
                    <mat-error *ngIf="form.get('firstName')?.hasError('required')">Required</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Last Name</mat-label>
                    <input formControlName="lastName" matInput />
                    <mat-error *ngIf="form.get('lastName')?.hasError('required')">Required</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Email</mat-label>
                    <input formControlName="email" matInput />
                    <mat-error *ngIf="form.get('email')?.hasError('required')">Required</mat-error>
                </mat-form-field>
                <ui-error-display *ngIf="!isRegistering" [error]="error"></ui-error-display>
            </div>
        </div>
    </mat-card-content>
    <mat-card-actions align="end">
        <button
            *ngIf="!joinUrl"
            mat-raised-button
            color="primary"
            [disabled]="form.invalid || isRegistering"
            (click)="onRegister()"
        >
            <mat-spinner *ngIf="isRegistering" class="spinner" diameter="20"></mat-spinner>
            Register
        </button>
    </mat-card-actions>
</mat-card>

<ng-template #registered>
    <div fxLayout="row nowrap" fxLayoutAlign="start center" fxLayoutGap="10px">
        <a fxFlex="1 1" target="_blank" *ngIf="showJoinUrl" class="short-link" rel="nofollow" [href]="joinUrl">{{
            joinUrl
        }}</a>
        <button *ngIf="showJoinButton" fxFlex="0 0 auto" mat-raised-button color="primary" (click)="onJoinMeeting()">
            <mat-spinner *ngIf="isJoiningMeeting" class="spinner" diameter="20"></mat-spinner>Join Meeting<mat-icon
                >video_call</mat-icon
            >
        </button>
        <button
            fxFlex="0 0 auto"
            class="nowrap"
            *ngIf="showCopyLinkButton"
            mat-raised-button
            (click)="onCopyLinkToClipboard()"
        >
            <mat-icon>content_copy</mat-icon>
            Copy Link
        </button>
    </div>
    <ng-container *ngTemplateOutlet="allowGuestRegistration ? guest : empty"></ng-container>
</ng-template>

<ng-template #guest>
    <button
        *ngIf="!guestLink"
        mat-raised-button
        [disabled]="isRequestingLink"
        color="primary"
        (click)="onRequestGuestLink()"
    >
        <mat-spinner *ngIf="isRequestingLink" class="spinner" diameter="20"></mat-spinner> Request Guest Link<mat-icon
            >video_call</mat-icon
        >
    </button>
    <ng-container *ngIf="guestLink">
        Use this link to join the meeting as a guest:
        <span (click)="onCopyLink()">{{ guestLink }}</span>
    </ng-container>
</ng-template>

<ng-template #empty> </ng-template>
