import { Directive, ElementRef, Output, EventEmitter, OnInit, OnDestroy, Input, OnChanges } from '@angular/core';

@Directive({
    selector: '[viewportCheck]',
})
export class ViewportCheckDirective implements OnInit, OnDestroy, OnChanges {
    private observer: IntersectionObserver;

    @Input('viewportCheck') isEnabled: string | boolean | null = true;

    @Output() readonly intersecting: EventEmitter<boolean> = new EventEmitter();

    constructor(private element: ElementRef) {}

    ngOnInit(): void {
        this.observer = new IntersectionObserver(([entry]) => {
            this.intersecting.emit(entry.isIntersecting);
        });

        if (this.isEnabled !== false) {
            this.observer.observe(this.element.nativeElement);
        }
    }

    ngOnDestroy(): void {
        this.observer.disconnect();
    }

    update() {
        if (!this.observer) {
            return;
        }

        if (this.isEnabled !== false) {
            this.observer.observe(this.element.nativeElement);
        } else {
            this.observer.disconnect();
        }
    }

    ngOnChanges(): void {
        this.update();
    }
}
