import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { SessionGroup } from '@app/data/models';

@Component({
    selector: 'ui-session-list',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './session-list.component.html',
    styleUrls: ['./session-list.component.scss'],
})
export class SessionListComponent {
    @Input() hasLinkStarted = false;
    @Input() sessionGroups: SessionGroup[] = [];

    @Output() readonly sessionGroupSelected: EventEmitter<SessionGroup> = new EventEmitter();

    onSessionGroupSelected(session: SessionGroup) {
        this.sessionGroupSelected.emit(session);
    }
}
