import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Pipe({
    name: 'raw',
})
export class RawValuePipe implements PipeTransform {
    transform(group: FormGroup, name: string): any {
        return group.getRawValue()[name];
    }
}
