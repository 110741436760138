<mat-action-list>
    <mat-list-item
        class="session-group"
        [ngClass]="{ active: session.isActive }"
        (click)="onSessionGroupSelected(session)"
        *ngFor="let session of sessionGroups"
    >
        <img matListItemAvatar class="avatar" [src]="session.location?.flag" *ngIf="session.hasLocation" />
        <div matListItemAvatar class="avatar" *ngIf="!session.hasLocation">
            <mat-icon class="no-location">gps_off</mat-icon>
        </div>
        <time matListItemLine class="small mute">
            {{ session.lastActivityTimestamp | formatDate }}
        </time>
        <div matListItemLine>
            <ng-container *ngIf="session.hasLocation">
                {{ session.location?.city }}, {{ session.location?.region }}, {{ session.location?.country }}
            </ng-container>
            <ng-container *ngIf="!session.hasLocation">Unknown</ng-container>
        </div>
        <div class="badge-overflow" matListItemLine *ngIf="session.deviceInfo">
            <span
                matBadgePosition="after"
                matBadgeOverlap="false"
                [matBadgeHidden]="session.accessCount === 0 || !hasLinkStarted"
                [matBadge]="session.accessCount"
                class="badge access-badge"
            >
                {{ session.deviceInfo.browser }} ({{ session.deviceInfo.browserVersion }})
                {{ session.deviceInfo.os }}
                {{ session.deviceInfo.osVersion }}
            </span>
        </div>
        <div matListItemLine *ngIf="!session.deviceInfo">No Device Info</div>
        <div matListItemLine class="mute">Session Id: {{ session.sessionId }}</div>
    </mat-list-item>
</mat-action-list>
