<button mat-raised-button (click)="onExport()"><mat-icon>image</mat-icon>Export</button>
<div>
    <mat-list >
        <mat-list-item (click)="onSelectItem(item)" class="item" *ngFor="let item of filteredSessions" matRipple>
            {{ item.name }}
            <mat-icon
                class="webinar-icon"
                *ngIf="item.registrantInfo"
                [ngClass]="{
                    'has-registered': item.registrantInfo.hasRegistered,
                    'has-joined': item.registrantInfo.hasJoined
                }"
                (mouseover)="showTooltip(item.registrantInfo, origin)"
                (mouseout)="isTooltipOpen = false"
                cdkOverlayOrigin
                #origin="cdkOverlayOrigin"
                svgIcon="zoom_logo"
            ></mat-icon>
        </mat-list-item>
    </mat-list>
</div>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayHasBackdrop]="false"
    [cdkConnectedOverlayOrigin]="registrantOrigin"
    [cdkConnectedOverlayOpen]="isTooltipOpen"
>
    <ui-registrant-details [model]="model"></ui-registrant-details>
</ng-template>