import { Pipe, PipeTransform } from '@angular/core';
import { DEFAULT_TIME_ZONE, isValidDate } from '../util';

@Pipe({
    name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {
    now = new Date();

    transform(
        value: Date | string | null | undefined,
        timeZone: string | null | undefined = DEFAULT_TIME_ZONE,
    ): string | null {
        if (value === null || value === undefined) {
            return null;
        }

        if (typeof value === 'string') {
            value = new Date(value);
        }

        if (!isValidDate(value)) {
            return null;
        }

        if (timeZone === null || timeZone === undefined) {
            timeZone = DEFAULT_TIME_ZONE;
        }

        // Today at noon as Australian Eastern Daylight Time
        // new Date('1/12/2023, 12:00:00 PM GMT-07:00').toLocaleString('en-US', { timeZone: 'Australia/Sydney', timeZoneName: 'longOffset' })
        // '1/13/2023, 6:00:00 AM GMT+11:00'

        // Australia time back to Edmonton time
        // new Date('1/13/2023, 6:00:00 AM GMT+11:00').toLocaleString('en-US', { timeZone: 'America/Edmonton', timeZoneName: 'longOffset' })
        // '1/12/2023, 12:00:00 PM GMT-07:00'

        if (value instanceof Date) {
            // timeZone: 'Australia/Sydney'
            // timeZoneName:
            //      long - Australian Eastern Daylight Time
            //      longOffset - GMT+11:00
            //      longGeneric - Eastern Australia Time
            //      short - GMT+11
            //      shortOffset - GMT+11
            //      shortGeneric - Sydney Time

            // timeZone: 'America/Edmonton'
            // timeZoneName:
            //      long - Mountain Standard Time
            //      longOffset - GMT-07:00
            //      longGeneric - Mountain Time
            //      short - MST
            //      shortOffset - GMT-7
            //      shortGeneric - MT

            // timeZone: 'Pacific/Honolulu'
            // timeZoneName:
            //      long - Hawaii-Aleutian Standard Time
            //      longOffset - GMT-10:00
            //      longGeneric - Hawaii-Aleutian Standard Time
            //      short - HST
            //      shortOffset - GMT-10
            //      shortGeneric - HST

            // timeZone: 'America/New_York'
            // timeZoneName:
            //      long - Eastern Standard Time
            //      longOffset - GMT-05:00
            //      longGeneric - Eastern Time
            //      short - EST
            //      shortOffset - GMT-5
            //      shortGeneric - ET

            if (this.now.getFullYear() !== value.getFullYear()) {
                return new Intl.DateTimeFormat('en-US', {
                    year: 'numeric',
                    weekday: 'short',
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                    timeZone,
                    timeZoneName: 'short' as any,
                }).format(value);
            }

            return new Intl.DateTimeFormat('en-US', {
                weekday: 'short',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                timeZone,
                timeZoneName: 'short' as any,
            }).format(value);
        }

        return null;
    }
}
