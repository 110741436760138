<section>
    <div fxLayout="column nowrap" fxLayoutAlign="center" class="sections" *ngIf="sections | any">
        <!-- <div class="actions" *ngIf="showComplete">
            <button mat-raised-button [disabled]="submission?.isSubmitted" color="primary" (click)="onCompleteSubmission()">
                <mat-icon>check</mat-icon>
                <span>Complete Submission</span>
            </button>
        </div> -->
        <div class="admin-form-section" fxFlex="1 1 80%" *ngFor="let page of sections; index as ix; first as isFirst">
            <div class="divider">
                <mat-divider *ngIf="!isFirst"> </mat-divider>
                <!-- <mat-icon>unfold_more</mat-icon> -->
                Page {{ ix + 1 }} of {{ sections.length }}
            </div>
            <ui-form-section class="section" [model]="page"> </ui-form-section>

            <ng-container *ngFor="let child of page.children">
                <ng-container [ngSwitch]="child.sectionType">
                    <ui-form-section-text
                        class="section"
                        *ngSwitchCase="FormSectionType.Text"
                        [model]="child"
                    ></ui-form-section-text>
                    <ui-form-section-question
                        class="section"
                        *ngSwitchCase="FormSectionType.Question"
                        [readonly]="true"
                        [print]="true"
                        [model]="child"
                    ></ui-form-section-question>
                    <ui-form-section-download
                        class="section"
                        *ngSwitchCase="FormSectionType.File"
                        [readonly]="true"
                        [model]="child"
                    ></ui-form-section-download>
                    <ui-form-section-media
                        class="section"
                        *ngSwitchCase="FormSectionType.Media"
                        [readonly]="true"
                        [model]="child"
                    ></ui-form-section-media>
                    <ui-form-section-event
                        class="section"
                        *ngSwitchCase="FormSectionType.Event"
                        [payments]="getPaymentsForSection(child)"
                        [readonly]="true"
                        [model]="$any(child)"
                    ></ui-form-section-event>
                    <ng-container *ngSwitchCase="FormSectionType.Booking">
                        <mat-card class="section">
                            <mat-card-header>
                                <mat-card-title>
                                    {{ child.title }}
                                </mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <ng-container *ngIf="hasCalendlyResult(child); else noCalendly">
                                    <ng-container *ngTemplateOutlet="calendlyEvent; context: { data: child }">
                                    </ng-container>
                                </ng-container>
                            </mat-card-content>
                        </mat-card>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
</section>

<ng-template #calendlyEvent let-data="data">
    <ui-calendly-event [event]="getScheduledEvent(data)" (loadEvent)="onLoadScheduledEvent(data)"></ui-calendly-event>
</ng-template>

<ng-template #noCalendly> User has not yet scheduled a meeting </ng-template>
