import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Submission } from '@app/data/models';

@Component({
    selector: 'ui-form-submission-list',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './form-submission-list.component.html',
    styleUrls: ['./form-submission-list.component.scss'],
})
export class FormSubmissionListComponent {
    @Input() submissions: Submission[] | null = [];

    @Output() readonly submissionSelected: EventEmitter<Submission> = new EventEmitter();

    onSessionGroupSelected(session: Submission) {
        this.submissionSelected.emit(session);
    }
}
