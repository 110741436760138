import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { ScheduledEvent } from '@app/data/models';
import { DEFAULT_TIME_ZONE, formatDatetimeWithTimeZone } from '@app/shared/util';

@Component({
    selector: 'ui-calendly-event',
    templateUrl: './calendly-event.component.html',
    styleUrls: ['./calendly-event.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendlyEventComponent implements OnChanges {

    get isActive(): boolean {
        return this.event?.status === 'active';
    }

    get isCancelled(): boolean {
        return this.event?.status === 'canceled';
    }

    @Input() event: ScheduledEvent | null = null;

    @Output() readonly loadEvent: EventEmitter<void> = new EventEmitter();

    formatDate(date: Date | undefined): string {
        if (!date) {
            return '';
        }

        const result = formatDatetimeWithTimeZone(date, DEFAULT_TIME_ZONE);

        return `${result}`;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['event'] && !this.event) {
            this.loadEvent.emit();
        }
    }
}
