import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { DebugState } from '../state';
import { Environment } from '@app/shared/models';
import { APP_ENVIRONMENT } from '@app/shared/tokens';
import { Store } from '@ngxs/store';

@Injectable()
export class ThumbprintInterceptor implements HttpInterceptor {
    constructor(private store: Store, @Inject(APP_ENVIRONMENT) private env: Environment) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const thumbprint = this.store.selectSnapshot(DebugState.getThumbprint());
        const visitorId = this.store.selectSnapshot(DebugState.getVisitorId());
        return next.handle(this.addHeaders(req, thumbprint, visitorId));
    }

    addHeaders(request: HttpRequest<any>, thumbprint: string | null, visitorId: string | null): HttpRequest<any> {
        const headers: { [key: string]: string } = {};

        if (request.url.indexOf(this.env.serverUrl) === -1) {
            return request;
        }

        if (!request.headers.has('X-thumbprint-override') && thumbprint) {
            headers['X-thumbprint-override'] = thumbprint;
        }

        if (!request.headers.has('X-visitor-id-override') && visitorId) {
            headers['X-visitor-id-override'] = visitorId;
        }

        return request.clone({
            setHeaders: headers,
        });
    }
}
