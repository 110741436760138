import { Pipe, PipeTransform } from '@angular/core';

const BYTES_IN_GIGABYTE = 1073741824;
const BYTES_IN_MEGABYTE = 1048576;

@Pipe({
    name: 'size',
})
export class SizePipe implements PipeTransform {
    transform(value: string | number): string {
        let bytes: number = value as number;

        if (typeof value === 'string') {
            bytes = parseInt(value);
        }

        const gigabytes = bytes / BYTES_IN_GIGABYTE;
        const megabytes = bytes / BYTES_IN_MEGABYTE;

        if (gigabytes > 1) {
            return `${gigabytes.toFixed(2)} GB`;
        }

        return `${megabytes.toFixed(2)} MB`;
    }
}
