import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';

import { DiagnosisComponent } from './components';
import {
    BrowserCheckDialogComponent,
    DenyBrowserDialogComponent,
    DenyIncognitoDialogComponent,
    DenyiOSDialogComponent,
    DenyRequestDesktopDialogComponent,
    RedirectMigrationDialogComponent,
} from './dialogs';
import { AuthState, CoreState, NotificationsState } from './state';
import { MediaPlayerState } from './state/media-player.state';

const COMPONENTS = [
    DenyBrowserDialogComponent,
    DenyIncognitoDialogComponent,
    DenyiOSDialogComponent,
    DenyRequestDesktopDialogComponent,
    DiagnosisComponent,
    BrowserCheckDialogComponent,
    RedirectMigrationDialogComponent,
];

const MATERIAL = [
    MatDialogModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatSnackBarModule,
    MatProgressBarModule,
];

@NgModule({
    declarations: [...COMPONENTS],
    imports: [
        CommonModule,
        HttpClientModule,

        ...MATERIAL,

        NgxsRouterPluginModule.forRoot(),
        NgxsFormPluginModule.forRoot(),
        NgxsModule.forRoot([AuthState, CoreState, NotificationsState, MediaPlayerState]),
    ],
    exports: [DiagnosisComponent],
})
export class DataModule {}
