import { IndividualConfig } from "ngx-toastr";

const ns = '[Notification]';

export class Success {
    static readonly type = `${ns} Success`;
    constructor(public message: string, public config?: Partial<IndividualConfig<any>>) {}
}

export class Acknowledge {
    static readonly type = `${ns} Acknowledge`;
    constructor(public message: string, public allowDismiss: boolean) {}
}

export class Warning {
    static readonly type = `${ns} Warning`;
    constructor(public message: string, public warnings: string[], public allowDismiss: boolean) {}
}

export class Info {
    static readonly type = `${ns} Info`;
    constructor(public message: string, public config?: Partial<IndividualConfig<any>>) {}
}

export class Error {
    static readonly type = `${ns} Error`;
    constructor(public message: string, public error?: any, public config?: Partial<IndividualConfig<any>>) {}
}

export class Progress {
    static readonly type = `${ns} Progress`;
    constructor(public message: string, public progress: number, public isComplete: boolean) {}
}
