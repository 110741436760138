import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { ErrorCodes, LinkExpiration, TimerUnit } from '@app/shared/enums';
import { formatDatetimeWithTimeZone, getErrorCodeMessage, hasContent } from '@app/shared/util';

@Component({
    selector: 'ui-link-notice',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: `./link-notice.component.html`,
    styleUrls: [`./link-notice.component.scss`],
})
export class LinkNoticeComponent implements OnChanges {
    get isNotice(): boolean {
        const noticeErrorCodes = [
            ErrorCodes.LinkRegistrationEnded,
            ErrorCodes.LinkNotApproved,
            ErrorCodes.LinkHasNotStarted,
        ];
        const { errorCode } = this.error as Meta;

        return noticeErrorCodes.includes(errorCode);
    }

    headerClass = '';
    title: string | null = null;
    errorMessage: string | null = null;
    message: string | null = null;

    showLinkNotStarted = false;
    showRegistrationCutoff = false;
    showLinkWillBegin = false;
    showLinkBegan = false;
    showPendingApproval = false;
    showErrorMessage = false;
    showMessage = false;

    showCountdown = false;
    startDate: Date | null = null;

    @Input() error: Meta | null = null;

    @Output() readonly logout = new EventEmitter<void>();
    @Output() readonly countDown = new EventEmitter<void>();

    onCountDown() {
        this.countDown.emit();
    }

    update() {
        if (this.isNotice) {
            this.updateNoticeMessages();
        } else {
            this.updateErrorMessages();
        }
    }

    onLogout() {
        this.logout.emit();
    }

    updateErrorMessages() {
        const meta = this.error as Meta;
        this.showErrorMessage = true;
        this.headerClass = 'inactive';
        this.title = 'Error';
        this.errorMessage = getErrorCodeMessage(meta.errorCode);

        switch (meta.errorCode) {
            case ErrorCodes.LinkSessionLimit:
                this.title = 'Session limit reached';
                break;
            case ErrorCodes.LinkOneTimeAccess:
                this.title = 'One-time access';
                break;
        }
    }

    updateNoticeMessages() {
        const meta = this.error as Meta;

        if (meta.hasRegistrationEndDate) {
            if (!meta.isLateRegistration && meta.isApproved && !meta.hasLinkStarted) {
                this.startDate = new Date(meta.startDate as Date);
                this.title = 'You Have Been Approved!';
                this.headerClass = 'active';
                this.showLinkNotStarted = true;
                this.showRegistrationCutoff = true;
                this.showLinkWillBegin = true;
            } else if (!meta.isLateRegistration && !meta.isApproved && meta.hasLinkStarted) {
                this.headerClass = 'active';
                this.title = 'Link has started';
                this.showPendingApproval = true;
                this.showRegistrationCutoff = true;
                this.showLinkBegan = true;
            } else if (!meta.isLateRegistration && !meta.isApproved && !meta.hasLinkStarted) {
                this.headerClass = 'inactive';
                this.title = 'Link not started';
                this.showPendingApproval = true;
                this.showRegistrationCutoff = true;

                if (meta.linkExpiration === LinkExpiration.Date) {
                    this.startDate = new Date(meta.startDate as Date);
                    this.showLinkWillBegin = true;
                } else if (meta.linkExpiration === LinkExpiration.Timer) {
                    this.showLinkWillBegin = false;
                }
            } else if (meta.isLateRegistration && !meta.isApproved && !meta.hasLinkStarted) {
                this.headerClass = 'inactive';
                this.title = 'Link not started';
                this.showRegistrationCutoff = true;
                this.showPendingApproval = true;

                if (meta.linkExpiration === LinkExpiration.Date) {
                    this.startDate = new Date(meta.startDate as Date);
                    this.showLinkWillBegin = true;
                } else if (meta.linkExpiration === LinkExpiration.Timer) {
                    this.showLinkWillBegin = false;
                }
            } else if (meta.isLateRegistration && meta.isApproved && !meta.hasLinkStarted) {
                this.headerClass = 'active';
                this.startDate = new Date(meta.startDate as Date);
                this.title = 'You have been approved!';
                this.showLinkNotStarted = true;
                this.showRegistrationCutoff = true;
                this.showLinkWillBegin = true;
            } else if (meta.isLateRegistration && !meta.isApproved && meta.hasLinkStarted) {
                this.headerClass = 'active';
                this.startDate = new Date(meta.startDate as Date);
                this.title = 'Link has started';
                this.showRegistrationCutoff = true;
                this.showPendingApproval = true;
                this.showLinkBegan = true;
            }
        } else {
            if (meta.isApproved && !meta.hasLinkStarted) {
                this.headerClass = 'active';
                this.title = 'You have been approved!';
                this.showLinkNotStarted = true;

                if (meta.linkExpiration === LinkExpiration.Date) {
                    this.startDate = new Date(meta.startDate as Date);
                    this.showLinkWillBegin = true;
                } else if (meta.linkExpiration === LinkExpiration.Timer) {
                    this.showLinkWillBegin = false;
                }
            } else if (!meta.isApproved && !meta.hasLinkStarted) {
                this.headerClass = 'inactive';
                this.title = 'Link not started';
                this.showPendingApproval = meta.requiresApproval;

                if (meta.linkExpiration === LinkExpiration.Date) {
                    this.startDate = new Date(meta.startDate as Date);
                    this.showLinkWillBegin = true;
                } else if (meta.linkExpiration === LinkExpiration.Timer) {
                    this.showLinkWillBegin = false;
                }
            } else if (!meta.isApproved && meta.hasLinkStarted) {
                this.headerClass = 'active';
                this.startDate = new Date(meta.startDate as Date);
                this.title = 'Link has started';
                this.showPendingApproval = meta.requiresApproval;

                this.showLinkBegan = meta.startDate !== null;

                if (meta.startDate === null) {
                    this.headerClass = 'pending';
                    this.title = 'Submission Received';
                }
            }
        }

        if (this.startDate && !meta.hasLinkStarted) {
            this.showCountdown = true;
        }

        if (meta.message && hasContent(meta.message, true)) {
            this.showMessage = true;
            this.message = meta.message || '';
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['error']) {
            this.update();
        }
    }

    formatDate(date: Date | undefined, timeZone: string): string {
        if (!date) {
            return '';
        }

        if (typeof date === 'string') {
            date = new Date(date);
        }

        const result = formatDatetimeWithTimeZone(date, timeZone);

        return `${result}`;
    }
}

interface Meta {
    isSubmitted: boolean;
    isApproved: boolean;
    requiresApproval: boolean;
    hasRegistration: boolean;
    hasRegistrationEnded: boolean;
    hasRegistrationEndDate: boolean;
    hasLinkStarted: boolean;
    isLateRegistration: boolean;
    registrationEndDate?: Date;
    message?: string | null;

    errorCode: ErrorCodes;
    startDate?: Date;
    endDate?: Date;
    timer?: number;
    timerUnit?: TimerUnit;
    timerEndDate?: Date;
    timeZone: string;
    hasQuestions: boolean;
    linkExpiration: LinkExpiration;
}
